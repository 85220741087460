import LockIcon from "@mui/icons-material/Lock";
import { Button, Dialog, InputAdornment, TextField } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { unlockTransfer } from "../../../api";
import { Spinner } from "../../../components/spinner";
import { ApiException, Transfer } from "../../../entities";
import { isApiException } from "../../../utils/typeGuard";
import MailboxExceptionDialog from "../../mailbox/components/errorDialog";

type PasswordProtectedPageProp = {
  transferRecipientId?: string;
  onTransferUnlockOrBLocked: (unlockedTransfer: Transfer) => void;
  passwordAttemptsLeft?: number;
};

enum PasswordFieldError {
  blankPasswordField,
  incorrectPassword,
}

/**
 * Represents the a locked TransferPage when the transfer is password protected
 *
 * @returns a JSX element representing dialog containing a password field.
 */
export default function PasswordProtectedPage(
  passwordProtectedPageProp: PasswordProtectedPageProp
) {
  const { t } = useTranslation();

  const userInputPasswordRef = useRef<string | undefined>(undefined);
  const passwordAttemptsLeftRef = useRef<number | undefined>(
    passwordProtectedPageProp.passwordAttemptsLeft
  );
  const userFacingErrorRef = useRef<Error | null>(null);

  const [errors, setErrors] = useState<Set<PasswordFieldError>>(new Set());
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let helperText: string | null = null;

  const isPasswordFieldBlank: boolean = errors.has(
    PasswordFieldError.blankPasswordField
  );
  const isInputPasswordIncorred: boolean = errors.has(
    PasswordFieldError.incorrectPassword
  );

  if (isPasswordFieldBlank) {
    helperText = t(`Password field cannot be empty.`);
  } else if (isInputPasswordIncorred) {
    if (passwordAttemptsLeftRef.current === 1) {
      helperText = t(
        `Incorrect password. One more attempt until the transfer is blocked.`
      );
    } else {
      helperText = t(`Incorrect password.`);
    }
  } else {
    helperText = "";
  }

  // Returns `false` if input password is invalid, i.e, blank or null input.
  const isInputValid = () => {
    // blank password field
    if (!userInputPasswordRef.current) {
      setErrors(
        //
        (currentErrors) => {
          const newErrors: Set<PasswordFieldError> = new Set(currentErrors);
          newErrors.add(PasswordFieldError.blankPasswordField);
          return newErrors;
        }
      );
      return false;
    } else {
      setErrors(
        //
        (currentErrors) => {
          const newErrors: Set<PasswordFieldError> = new Set(currentErrors);
          newErrors.clear();
          return newErrors;
        }
      );
      return true;
    }
  };

  const onUnlockButtonPressed = async () => {
    setIsLoading(true);

    try {
      const lockedOrUnlockedTransfer: Transfer = await unlockTransfer({
        userInputPassword: userInputPasswordRef.current ?? "",
        transferRecipientId: passwordProtectedPageProp.transferRecipientId,
      });

      if (
        !lockedOrUnlockedTransfer.isLocked ||
        lockedOrUnlockedTransfer.isBlocked
      ) {
        passwordProtectedPageProp.onTransferUnlockOrBLocked(
          lockedOrUnlockedTransfer
        );
      } else {
        passwordAttemptsLeftRef.current =
          lockedOrUnlockedTransfer.passwordAttempts;

        setErrors(
          //
          (currentErrors) => {
            const newErrors: Set<PasswordFieldError> = new Set(currentErrors);
            newErrors.add(PasswordFieldError.incorrectPassword);
            return newErrors;
          }
        );
      }

      setIsLoading(false);
    } catch (error) {
      userFacingErrorRef.current = new Error();
      userFacingErrorRef.current.name = t(`Something went wrong`);
      const genericErrorMessage: string = t(
        `Something went wrong. If refreshing the page does not fix the problem then contact support. `
      );
      if (!(error instanceof AxiosError)) {
        userFacingErrorRef.current.message = (error as Error).message;
        return;
      }

      const response: AxiosResponse<unknown, any> | undefined = error.response;

      if (!response) {
        userFacingErrorRef.current.message = genericErrorMessage;
        return;
      }

      const isResponseDataApiException: boolean = isApiException(response.data);

      if (isResponseDataApiException) {
        const apiException: ApiException = response.data as ApiException;

        userFacingErrorRef.current.message = apiException.error.details.reduce(
          (acc, curr) => acc + " " + curr,
          ""
        );
      } else {
        const isAxiosResponseDataString: boolean =
          (response as AxiosResponse<unknown, any>).data instanceof String;

        if (isAxiosResponseDataString) {
          userFacingErrorRef.current.message = (
            response as AxiosResponse<unknown, any>
          ).data as string;
        } else {
          userFacingErrorRef.current.message = genericErrorMessage;
        }
      }
    }
  };

  return (
    <>
      <Dialog
        open={true}
        maxWidth="md"
        PaperProps={{
          style: { borderRadius: 0 },
        }}
      >
        <div className="h-[470px] w-[680px] pl-[86px] pr-[74px] pt-[70px] pb-10">
          <>
            <div className="text-xl">
              {t(`This transfer is password protected!`)}
            </div>
            <div className="pt-10 font-light">
              {t(
                `Please enter the password that the sender has provided you with:`
              )}
            </div>
            <div className="pt-10">
              <TextField
                error={errors.size > 0}
                helperText={helperText ?? " "}
                fullWidth={true}
                size="small"
                variant="filled"
                type="password"
                label={<div className="pl-12"> {t(`Password`)}</div>}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon
                        sx={{
                          color: "#747474",
                          fontSize: `20px`,
                          marginRight: `10px`,
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                autoFocus={true}
                onChange={(prop) => {
                  userInputPasswordRef.current = prop.target.value;
                }}
                onKeyUp={(prop) => {
                  if (!isInputValid()) return;

                  if (prop.key === "Enter") {
                    onUnlockButtonPressed();
                  }
                }}
                onBlur={isInputValid}
              />
            </div>
            <div className="pt-[15px] text-xs">
              {t(
                `If you are a registered TeamBeam user, you can also log in with your TeamBeam credentials to access the transfer.`
              )}
            </div>
            <div className="mt-10 flex justify-end">
              <Button
                variant="contained"
                onClick={onUnlockButtonPressed}
                sx={{
                  borderRadius: 10,
                }}
              >
                {isLoading ? (
                  <Spinner color="#FFFFFF" size={16} />
                ) : (
                  t(`Unlock Transfer`)
                )}
              </Button>
            </div>
          </>
        </div>
      </Dialog>
      {userFacingErrorRef.current && (
        <MailboxExceptionDialog
          title={userFacingErrorRef.current.name}
          description={userFacingErrorRef.current.message}
          onDismiss={() => {
            userFacingErrorRef.current = null;
            setIsLoading(false);
          }}
        />
      )}
    </>
  );
}
