import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { ReactNode } from "react";
import useSkpTheme from "../../hooks/useSkpTheme";
import { cssVar } from "../../utils/cssVar";

type SideDrawerThemeProviderProp = {
  children: ReactNode;
};

export const SideDrawerThemeProvider = (
  sideDrawerThemeProviderProp: SideDrawerThemeProviderProp
) => {
  useSkpTheme();

  const sideDrawerTheme = createTheme({
    palette: {
      primary: {
        main: cssVar("--primary-color"),
      },
      secondary: {
        main: cssVar("--primary-light-color"),
      },
    },
    components: {
      // ===================================== Transfer Priority toggle button ======================================
      MuiToggleButton: {
        styleOverrides: {
          root: {
            ":hover": {
              backgroundColor: cssVar("--primary-light-color"),
            },
            "&.Mui-selected": {
              ":hover": {
                backgroundColor: cssVar("--primary-color"),
                filter: "brightness(0.95)",
              },
              backgroundColor: cssVar("--primary-color"),
            },
          },
        },
      },
      // ============================================================================================================
      //
      //
      // ================================== Transfer Protection slider ==============================================
      MuiSlider: {
        styleOverrides: {
          mark: {
            width: 4,
            height: 4,
            borderRadius: "50%",
            backgroundColor: "white",
          },
          thumb: {
            backgroundImage: cssVar("--slider-linear-gradient"),
          },
          track: {
            border: "none",
            backgroundImage: cssVar("--slider-linear-gradient"),
          },
          rail: {
            marginLeft: "12px",
            marginRight: "12x",
            height: 6,
            backgroundImage: cssVar("--slider-linear-gradient"),
          },
        },
      },
    },
    // ============================================================================================================
  });

  return (
    <ThemeProvider theme={sideDrawerTheme}>
      {sideDrawerThemeProviderProp.children}
    </ThemeProvider>
  );
};
