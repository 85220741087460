import {
  ProtectionType,
  TransferDraft,
  TransferDraftErrors,
} from "../entities";

/**
 * Checks transfer draft form for any missed out `TransferDraftError` &
 * returns a set of errors.
 *
 * @param transferDraft
 * @returns a set of TransferDraftError.
 */
export const validateFormAndReturnErrorIfAny = (
  transferDraft: TransferDraft
): Set<TransferDraftErrors> => {
  const transferDraftErrors: Set<TransferDraftErrors> =
    new Set<TransferDraftErrors>();

  const isSenderNameInvalid: boolean = !transferDraft.anon.name;

  const isSenderEmailInvalid: boolean = !transferDraft.anon.email;

  const isPswdEmpty: boolean = !transferDraft.password;
  const isRepeatPswdEmpty: boolean = !transferDraft.repeatPassword;

  const isPswdProtected: boolean =
    transferDraft.protectionType ===
    (Object.values(ProtectionType)[1] as ProtectionType);

  const isNoFileAdded: boolean = !transferDraft.files.length;

  // Sender's name validation
  if (isSenderNameInvalid) {
    transferDraftErrors.add(TransferDraftErrors.blankSenderName);
  }

  // Sender's email validation
  if (isSenderEmailInvalid) {
    transferDraftErrors.add(TransferDraftErrors.invalidSenderEmail);
  }

  // Password validation
  if (isPswdProtected) {
    if (isPswdEmpty) {
      transferDraftErrors.add(TransferDraftErrors.blankPassword);
    }
    if (isRepeatPswdEmpty) {
      transferDraftErrors.add(TransferDraftErrors.blankRepeatPassword);
    }
  }

  // File count validation
  if (isNoFileAdded) {
    transferDraftErrors.add(TransferDraftErrors.invalidFileCount);
  }

  return transferDraftErrors;
};
