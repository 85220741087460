import { useTranslation } from "react-i18next";
import { Spacer } from "../components/layout";
import { TagAndValue, extractNonNestedTags } from "../utils/tagParser";
import NotFoundPageSkeleton from "./skeletons/NotFound";

type ContentProp = {
  // Position of page's content in DOM.
  position: string;
};

type LogoProp = {
  // Alignment of this component along its container's cross-axis.
  crossAxisalignment: string;
};

/**
 * This page is rendred when either the `vanityLink` or (`publicboxIdx` & `publicboxKey`)
 * is missing or incorrect.
 *
 * @returns a JSX element representing [NotFoundPage].
 */
export default function NotFoundPage() {
  return (
    <NotFoundPageSkeleton>
      <Content position="absolute" />
    </NotFoundPageSkeleton>
  );
}

/**
 * Content of the page.
 *
 * @param {ContentProp} contentProp
 *
 * @returns a JSX element representing content of the page.
 */
function Content(contentProp: ContentProp) {
  return (
    <div className={`${contentProp.position} flex h-screen w-screen flex-col`}>
      <TextualContent />
      <Spacer />
      <Logo crossAxisalignment="self-end" />
    </div>
  );
}

/**
 * Textual content of the page.
 *
 * @returns a JSX element representing textual content.
 */
function TextualContent() {
  const { t } = useTranslation();

  const teambeamHomepageText = getJSXElementsFromExtractedTagAndValue(
    extractNonNestedTags(
      t(
        `You may be able to reach your destination via the <p>TeamBeam-Homepage</p>.`
      )
    )
  );

  const teambeamSupportText = getJSXElementsFromExtractedTagAndValue(
    extractNonNestedTags(
      t(`Alternatively, you may contact the <s>TeamBeam-Support</s>.`)
    )
  );
  return (
    <div className="mt-48 ml-24 mr-24 mb-48 place-content-evenly">
      <div className="text-[26px] font-medium">
        {t(`The page was not found`)}
      </div>
      <div className="mt-[30px] text-xl">
        {t(`Please check the URL and enter it again if necessary.`)}
      </div>
      <div className="mt-[60px] text-[26px]">
        {t(`The page still wasn't found?`)}
      </div>
      <div className="mt-[30px] text-xl">{teambeamHomepageText}</div>
      <div className="mt-[30px] text-xl">{teambeamSupportText}</div>
    </div>
  );
}

/**
 * Utility function for obtaining JSX elements from extracted tags.
 * @returns a JSX element containing timezone.
 */
const getJSXElementsFromExtractedTagAndValue = (
  extractedTagsAndValues: TagAndValue[]
) => {
  return extractedTagsAndValues.map((extractedTagAndValue, index) => {
    switch (extractedTagAndValue.tag) {
      case "p":
        return (
          <span key={index} className={`cursor-pointer font-medium`}>
            <a
              href="https://www.teambeam.de/en/"
              target="_self"
              style={{ color: "var(--primary-color)", textDecoration: "none" }}
            >
              {extractedTagAndValue.value}
            </a>
          </span>
        );
      case "s":
        return (
          <span key={index} className={`cursor-pointer font-medium`}>
            <a
              href="mailto:support@teambeam.de"
              target="_self"
              style={{ color: "var(--primary-color)", textDecoration: "none" }}
            >
              {extractedTagAndValue.value}
            </a>
          </span>
        );
      case null:
        return (
          <span key={index} className="text-xl">
            {extractedTagAndValue.value}
          </span>
        );
      default:
        return <></>;
    }
  });
};

/**
 * Teambeam logo of 225px x 40px dimension.
 *
 * @param {LogoProp} logoProp
 *
 * @returns a JSX element represeting teambeam logo.
 *
 * @see LogoProp
 */
function Logo(logoProp: LogoProp) {
  return (
    <img
      className={`${logoProp.crossAxisalignment} mr-[15px] mb-[20px]`}
      src={process.env.PUBLIC_URL + "/assets/teambeam_Logo weiss.svg"}
      alt=""
    />
  );
}
