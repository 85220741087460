import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      de: {
        translation: require("./translations/de.json"),
      },
    },
    fallbackLng: { default: ["en"] },
    debug: false,
    detection: {
      order: ["querystring", "navigator"],
      lookupQuerystring: "lng",
    },
    interpolation: {
      escapeValue: false,
    },
  });

i18n.languages = ["de", "en"];

export default i18n;
