type TagAndValue = {
  tag?: string | null;
  value: string;
};

/**
 * Turns "abc <x>def</x> ghi" into [(null, "abc "), ("x", "def"), (null, " ghi")]
 *
 * Does not work with nested tags.
 *
 * Is meant as a simple way to split translations strings where portions of the translations
 * need to be extracted, e.g. to be made interactable
 *
 * @param input string containing tags
 * @returns a list of
 */
export const extractNonNestedTags = (input: string) => {
  const result: TagAndValue[] = [];
  const regex = /<(\w*)>(.*?)<\/\1>/g;

  let match;
  let lastIndex = 0;

  while ((match = regex.exec(input)) !== null) {
    // Add text before the match
    if (match.index > lastIndex) {
      result.push({
        tag: null,
        value: input.substring(lastIndex, match.index),
      });
    }

    // Add the matched content
    result.push({ tag: match[1], value: match[2] || "" });

    lastIndex = regex.lastIndex;
  }

  // Add the remaining text after the last match
  if (lastIndex < input.length) {
    result.push({ tag: null, value: input.substring(lastIndex) });
  }

  return result;
};

export type { TagAndValue };
