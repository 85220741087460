import { ReactNode, createContext, useContext, useReducer } from "react";
import {
  ProtectionType,
  TransferDraft,
  TransferDraftErrors,
} from "../entities";
import {
  TransferDraftAction,
  TransferDraftActionType,
  transferDraftReducer,
} from "../reducers/transferDraft";
import { DropzoneState, useDropzone } from "react-dropzone";
import React from "react";

const defaultTransferDraft: TransferDraft = {
  files: [],
  subject: "",
  description: "",
  receivers: [],
  anon: {
    name: "",
    email: "",
  },
  ttl: 1,
  acknowledgement: true,
  priority: 3,
  protectionType: Object.values(ProtectionType)[0] as ProtectionType,
  errors: new Set<TransferDraftErrors>(),
  password: undefined,
  repeatPassword: undefined,
};

const OuterDropzoneStateContext = createContext<DropzoneState | undefined>(
  undefined
);

const TransferDraftContext = createContext<TransferDraft>(defaultTransferDraft);

const TransferDraftDispatchContext = createContext<
  React.Dispatch<TransferDraftAction>
>(() => {});

function useTransferDraft(): TransferDraft {
  return useContext(TransferDraftContext);
}

function useTransferDraftDispatch(): React.Dispatch<TransferDraftAction> {
  return useContext(TransferDraftDispatchContext);
}

function useOuterDropzoneState(): DropzoneState {
  let outerDropzoneState = useContext(OuterDropzoneStateContext);
  const transferDraftDispatch = useTransferDraftDispatch();
  let outerDropzoneState2 = useDropzone({
    // so as to disable the opening of native file selector
    noClick: true,
    onDragOver: (prop) => (prop.dataTransfer.dropEffect = "none"),
    onDropAccepted: (acceptedFiles: File[]) =>
      transferDraftDispatch({
        type: TransferDraftActionType.filesAdd,
        addedFiles: acceptedFiles,
      }),
  });

  return outerDropzoneState ?? outerDropzoneState2;
}

interface TransferDraftProviderProp {
  children: ReactNode;
}

function TransferDraftAndOuterDropzoneProvider({ children }: TransferDraftProviderProp) {
  const [transferDraft, dispatchTransferAction] = useReducer<
    (transferDraft: TransferDraft, action: TransferDraftAction) => TransferDraft
  >(transferDraftReducer, defaultTransferDraft);

  const outerDropzoneState = useDropzone({
    // so as to disable the opening of native file selector
    noClick: true,
    onDragOver: (prop) => (prop.dataTransfer.dropEffect = "none"),
    onDropAccepted: (acceptedFiles: File[]) =>
      dispatchTransferAction({
        type: TransferDraftActionType.filesAdd,
        addedFiles: acceptedFiles,
      }),
  });

  return (
    <TransferDraftContext.Provider value={transferDraft}>
      <TransferDraftDispatchContext.Provider value={dispatchTransferAction}>
        <OuterDropzoneStateContext.Provider value={outerDropzoneState}>
          {children}
        </OuterDropzoneStateContext.Provider>
      </TransferDraftDispatchContext.Provider>
    </TransferDraftContext.Provider>
  );
}

export {
  TransferDraftAndOuterDropzoneProvider,
  TransferDraftContext,
  TransferDraftDispatchContext,
  useTransferDraft,
  useTransferDraftDispatch,
  useOuterDropzoneState,
};
