import Button from "@mui/material/Button/Button";
import { useTranslation } from "react-i18next";
import NotFoundPageSkeleton from "./skeletons/NotFound";

type ErrorPageProp = {
  errorMessage: string;
};

type ContentProp = {
  // Position of [content] in DOM.
  position: string;
  errorMessage: string;
};

type TextualContentProp = ErrorPageProp;

type RefreshButtonProp = {
  // Top Margin of this [RefreshButton].
  marginTop: number;
};

/**
 * This page is rendred when then initial fetching of data, from server, fails.
 *
 * @param {ErrorPageProp} errorPageProp
 *
 * @returns a JSX element representing [ErrorPage].
 */
export default function ErrorPage(errorPageProp: ErrorPageProp) {
  return (
    <NotFoundPageSkeleton>
      <Content position="absolute" errorMessage={errorPageProp.errorMessage} />
    </NotFoundPageSkeleton>
  );
}

/**
 * Content of [ErrorPage].
 *
 * @param {ContentProp} contentProp
 *
 * @returns a JSX element representing [ErrorPage].
 */
function Content(contentProp: ContentProp) {
  return (
    <div className={`${contentProp.position} flex h-screen w-screen flex-col`}>
      <TextualContent errorMessage={contentProp.errorMessage} />
    </div>
  );
}

/**
 * Textual content of the page.
 *
 * @param {TextualContentProp} textualContentProp
 *
 * @returns a JSX element representing textual content.
 */
function TextualContent(textualContentProp: TextualContentProp) {
  const { t } = useTranslation();
  return (
    <div className="mt-48 ml-24 mr-24 mb-48 place-content-evenly">
      <div className="text-[26px] font-medium">
        {t(`The server is not responding`)}
      </div>
      <div className="mt-[20px] text-xl">
        {textualContentProp.errorMessage ??
          "Bitte warten Sie einen Moment und laden Sie die Seite dann erneut."}
      </div>
      <RefreshButton marginTop={5} />
    </div>
  );
}

/**
 * Button to refresh the [ErrorPage].
 *
 * @param {RefreshButtonProp} refreshButtonProp
 *
 * @returns a JSX element refresh button.
 */
function RefreshButton(refreshButtonProp: RefreshButtonProp) {
  const { t } = useTranslation();
  return (
    <Button
      type="button"
      variant="contained"
      onClick={() => {
        window.location.reload();
      }}
      sx={{
        borderRadius: 3,
        paddingY: 0.2,
        paddingX: 1.8,
        marginTop: refreshButtonProp.marginTop,
      }}
    >
      {t(`Reload page`)}
    </Button>
  );
}
