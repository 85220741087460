import { useTranslation } from "react-i18next";
import {
  useTransferDraft,
  useTransferDraftDispatch,
} from "../../../../providers/transferDraft";
import { TextField } from "@mui/material";
import { TransferDraftActionType } from "../../../../reducers/transferDraft";

export default function MessageTextField() {
  const transferDraft = useTransferDraft();
  const transferDraftDispatch = useTransferDraftDispatch();
  const { t } = useTranslation();

  return (
    <TextField
      value={transferDraft.description}
      variant="filled"
      type="text"
      multiline
      label={t(`Message`)}
      minRows={5}
      InputProps={{
        disableUnderline: true,
      }}
      onChange={(newValue) =>
        transferDraftDispatch({
          type: TransferDraftActionType.descriptionUpdate,
          updatedDescription: newValue.target.value,
        })
      }
    />
  );
}
