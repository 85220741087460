import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button/Button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SideDrawerThemeProvider } from "../../../../providers/muiThemeProvider/sideDrawerThemeProvider";
import SideDrawer from "./sideDrawer";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { IconButton } from "@mui/material";
import useHashNavigation from "../../../../hooks/useHashNavigation";

export default function OptionsButton() {
  const { t } = useTranslation();
  const isSideDrawerOpenOnFirstRender: boolean =
    !!window.location.hash && window.location.hash === "#options";
  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState<boolean>(
    isSideDrawerOpenOnFirstRender
  );
  const { isMobileWidth } = useWindowDimensions();
  const { navigateToSection, clearSection } =
    useHashNavigation(setIsSideDrawerOpen);

  return (
    <div>
      {isMobileWidth ? (
        // [mobile] show full-screen options page.
        <IconButton onClick={() => navigateToSection("options")}>
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          variant="text"
          // [desktop] show options UI in a side drawer.
          onClick={() => navigateToSection("options")}
          startIcon={<MoreVertIcon />}
        >
          <div className="font-ibm-plex-sans font-light">
            {t(`Other options`)}{" "}
          </div>
        </Button>
      )}
      <SideDrawerThemeProvider>
        <SideDrawer
          isOpen={isSideDrawerOpen}
          // dismiss options UI.
          onDismiss={() => clearSection()}
        />
      </SideDrawerThemeProvider>
    </div>
  );
}
