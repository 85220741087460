import { useTranslation } from "react-i18next";
import {
  useTransferDraft,
  useTransferDraftDispatch,
} from "../../../../providers/transferDraft";
import { useEnvironment } from "../../../../providers/environment";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ProtectionType, TransferDraft } from "../../../../entities";
import { TransferDraftActionType } from "../../../../reducers/transferDraft";
import { ProtectionLock, ProtectionSolidLock } from "../protectionLock";
import { useState } from "react";
import { assertNever } from "../../../../utils/typeGuard";
import InfoIcon from "@mui/icons-material/Info";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";

type OptionsPageProp = {
  onDismiss: (updatedTransferDraft: TransferDraft) => void;
};

type LocksProp = {
  currentProtectionLevel: number;
  onProtectionChange: (level: number) => void;
};

type ProtectionLevelSliderProp = {
  protectionLevel: number;
  onChange: (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => void;
};

type ProtectionLevelInfoProp = {
  protectionLevel: number;
};

/**
 * Contains some of the {@link TransferDraft}'s configurations.
 *
 * @returns JSX element representing a small page like component.
 */
export default function OptionsPage({ onDismiss }: OptionsPageProp) {
  const { t } = useTranslation();
  const transferDraft = useTransferDraft();
  const environment = useEnvironment()!;

  return (
    <div className="min-h-screen w-screen md:w-[295px] ">
      <div className="mx-8 sm:mt-[110px] mt-20">
        <div className="flex flex-row justify-start sm:justify-between">
          <div className="absolute left-4 sm:hidden">
            <IconButton
              sx={{
                color: "#1A1A1A",
                fontSize: 14,
              }}
              onClick={(_) => {
                onDismiss(transferDraft);
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="ml-8 mt-1.5 flex items-center font-sans text-xl font-semibold sm:ml-0 sm:mt-0">
            {t(`Options`)}
          </div>

          <div className="hidden sm:block">
            <IconButton
              sx={{
                color: "#1A1A1A",
                fontSize: 14,
              }}
              onClick={(_) => {
                onDismiss(transferDraft);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="mt-[80px]">
          <AcknowledgementCheckBox />
        </div>
        {environment.ttlEnabled && (
          <div className="mt-8 ">
            <TransferValidityOptions />
          </div>
        )}
        {environment.priorityEnabled && (
          <div className="mt-[51px]">
            <PriorityToggleButtons />
          </div>
        )}
        <div className="mt-[51px]">
          <CurrentProtectionLevel />
        </div>
      </div>
    </div>
  );
}

/**
 * Checkbox for setting the acknowledgement configuration of {@link TransferDraft}.
 *
 * @returns JSX element representing {@link Checkbox}.
 */
function AcknowledgementCheckBox() {
  const { t } = useTranslation();
  const transferDraftDispatch = useTransferDraftDispatch();
  const transferDraft = useTransferDraft();

  return (
    <form>
      <FormControlLabel
        control={
          <Checkbox
            checked={transferDraft.acknowledgement}
            size="small"
            onChange={(event, isSelected) => {
              transferDraftDispatch({
                type: TransferDraftActionType.acknowledgementUpdate,
                isSelected: isSelected,
              });
            }}
          />
        }
        label={
          <span style={{ fontSize: "15px", lineHeight: "normal" }}>
            {t(`Acknowledgement`)}
          </span>
        }
      />
    </form>
  );
}

/**
 * Drop down menu for changing the expiration of {@link TransferDraft}
 *
 * @returns JSX element representing drop down menu.
 *
 * @see{@link Select}.
 */
function TransferValidityOptions() {
  const { t } = useTranslation();
  const transferDraft = useTransferDraft();
  const transferDraftDispatch = useTransferDraftDispatch();
  const environment = useEnvironment()!;
  const validityOptions = environment.expiration.values.sort((a, b) => a - b);
  return (
    <FormControl fullWidth>
      <InputLabel> {t(`Validity`)}</InputLabel>
      <Select
        value={transferDraft.ttl}
        label={t(`Validity`)}
        onChange={(event, child) => {
          transferDraftDispatch({
            type: TransferDraftActionType.ttlUpdate,
            updatedTtl: event.target.value as number,
          });
        }}
      >
        {validityOptions.map((validityOption) => {
          let label: string = validityOption.toString();
          label =
            validityOption === 1
              ? t(`1 Day`)
              : t(`{{number}} Days`, { number: validityOption });
          return (
            <MenuItem key={validityOption} value={validityOption}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

/**
 * Toggle buttons for changing the priority of {@link TransferDraft}.
 *
 * @returns JSX element representing group of horizontally aligned toggle buttons.
 *
 * @see{@link ToggleButtonGroup}.
 */
function PriorityToggleButtons() {
  const { t } = useTranslation();
  const transferDraftDispatch = useTransferDraftDispatch();
  const transferDraft = useTransferDraft();
  const { isMobileWidth } = useWindowDimensions();

  return (
    <div className="flex flex-col">
      <div className="text-[12px] text-[#747474]">{t(`Priority`)}</div>
      <div className="mt-1 self-center sm:self-auto">
        <ToggleButtonGroup
          fullWidth={true}
          size="small"
          value={transferDraft.priority}
          exclusive
          onChange={(event, updatedPriority) => {
            transferDraftDispatch({
              type: TransferDraftActionType.priorityUpdate,
              updatedPriority: updatedPriority,
            });
          }}
          sx={{
            width: isMobileWidth ? "70vw" : null,
          }}
        >
          <ToggleButton value={5}>
            <div
              className={` ${
                transferDraft.priority === 5
                  ? "text-[#FFFFFF]"
                  : "text-[#C7C7C7]"
              }  `}
            >
              -
            </div>
          </ToggleButton>
          <ToggleButton value={3}>
            <div
              className={` ${
                transferDraft.priority === 3
                  ? "text-[#FFFFFF]"
                  : "text-[#C7C7C7]"
              } `}
            >
              !
            </div>
          </ToggleButton>
          <ToggleButton value={1}>
            {" "}
            <div
              className={` ${
                transferDraft.priority === 1
                  ? "text-[#FFFFFF]"
                  : "text-[#C7C7C7]"
              } `}
            >
              !!
            </div>
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  );
}

/**
 * Contains {@link ProtectionLevelSlider} & {@link ProtectionLevelInfo} of {@link TransferDraft}.
 *
 * @returns JSX element to show the current protection configuration of the transfer draft
 * & also allows user to change the protection level configuration.
 */
function CurrentProtectionLevel() {
  const transferDraftDispatch = useTransferDraftDispatch();
  const transferDraft = useTransferDraft();

  // TODO need to find an approach without casting as `unknown`
  let currentProtectionLevel: number = ProtectionType[
    transferDraft.protectionType
  ] as unknown as number;

  const handleProtectionChange = (newValue: number) => {
    const enumValues = Object.values(ProtectionType);
    transferDraftDispatch({
      type: TransferDraftActionType.protectionTypeUpdate,
      updatedProtectionType: enumValues[newValue] as ProtectionType,
    });
  };

  return (
    <>
      <ProtectionLevelLockIcons
        currentProtectionLevel={currentProtectionLevel}
        onProtectionChange={handleProtectionChange}
      />
      <div className="ml-[10px] mr-[20px]">
        <ProtectionLevelSlider
          protectionLevel={currentProtectionLevel}
          onChange={(event, newValue) => handleProtectionChange(newValue as number)}
        />
      </div>
      <div className="mt-[16px]">
        <ProtectionLevelInfo protectionLevel={currentProtectionLevel} />
      </div>
    </>
  );
}

/**
 * Group of 3 lock icons representing different protection levels.
 *
 * @returns JSX element representing group of horizontally aligned lock icons.
 */
function ProtectionLevelLockIcons({ currentProtectionLevel, onProtectionChange }: LocksProp) {
  return (
    <div className="flex flex-row items-baseline justify-between">
      <button 
        onClick={() => onProtectionChange(0)}
      >
        {ProtectionType[currentProtectionLevel] === ProtectionType[0] ? (
          <ProtectionSolidLock size={20} />
        ) : (
          <ProtectionLock size={20} />
        )}
      </button>
      
      <button 
        onClick={() => onProtectionChange(1)}
      >
        {ProtectionType[currentProtectionLevel] === ProtectionType[1] ? (
          <ProtectionSolidLock size={25} />
        ) : (
          <ProtectionLock size={25} />
        )}
      </button>
      
      <button 
        onClick={() => onProtectionChange(2)}
      >
        {ProtectionType[currentProtectionLevel] === ProtectionType[2] ? (
          <ProtectionSolidLock size={35} />
        ) : (
          <ProtectionLock size={35} />
        )}
      </button>
    </div>
  );
}

/**
 * Slider with which the user can change the {@link ProtectionType} of {@link TransferDraft}.
 *
 * @returns JSX element representing Slider.
 */
function ProtectionLevelSlider({
  protectionLevel,
  onChange,
}: ProtectionLevelSliderProp) {
  return (
    <Slider
      value={protectionLevel}
      onChange={onChange}
      step={1}
      marks
      max={2}
    />
  );
}

/**
 * Name & description of the selected {@link ProtectionType}.
 *
 * @returns JSX element representing Protection level information.
 */
function ProtectionLevelInfo({ protectionLevel }: ProtectionLevelInfoProp) {
  const { t } = useTranslation();
  const [isShowDescription, setIsShowDescription] = useState<boolean>(false);

  let protectionLevelLabel: string;

  const protectionType: ProtectionType =
    protectionLevel as unknown as ProtectionType;

  switch (protectionType) {
    case ProtectionType.STANDARD:
      protectionLevelLabel = t(`STANDARD`);
      break;
    case ProtectionType.PASSWORTSCHUTZ:
      protectionLevelLabel = t(`PASSWORD PROTECTION`);
      break;
    case ProtectionType.ANGEMELDETEREMPFÄNGER:
      protectionLevelLabel = t(`AUTHENTICATED RECIPIENT`);
      break;
    default:
      return assertNever(protectionType);
  }

  return (
    <div className="flex flex-col items-center">
      <Button
        onClick={() => {
          setIsShowDescription(!isShowDescription);
        }}
        endIcon={
          <InfoIcon
            style={{
              fontSize: 16,
            }}
          />
        }
      >
        <div
          style={{ color: "var(--primary-color)" }}
          className="mt-0.5 text-[12px] font-medium"
        >
          {protectionLevelLabel}
        </div>
      </Button>
      {isShowDescription && (
        <ProtectionLevelDescription protectionType={protectionType} />
      )}
    </div>
  );
}

function ProtectionLevelDescription({
  protectionType,
}: {
  protectionType: ProtectionType;
}) {
  const { t } = useTranslation();

  return (
    <>
      {
        <p className="mt-[10px] text-[11px] text-[#747474]">
          {(() => {
            switch (protectionType) {
              case ProtectionType.STANDARD:
                return t(
                  `The recipient receives a download link consisting of a randomly generated combination of numbers and letters that cannot be guessed. All transfer data is transmitted and stored in encrypted form.`
                );
              case ProtectionType.PASSWORTSCHUTZ:
                return t(
                  `The transfer can only be unlocked if the recipient enters the password or is logged in to TeamBeam with the specified email address.`
                );
              case ProtectionType.ANGEMELDETEREMPFÄNGER:
                return t(
                  `To open the transfer, the recipient needs a TeamBeam account. If no account exists for the email address, the recipient will receive a link to create a free account.`
                );
              default:
                return assertNever(protectionType);
            }
          })()}
        </p>
      }
    </>
  );
}
