/**
 * This component creates an empty spacer that can be used
 * to tune the spacing between flex items.
 * In order for this component to work, this component
 * should be in a flex-col or flex-row
 *
 * @returns a JSX element representing
 *
 * @see{@link https://tailwindcss.com/docs/flex-grow}
 */
function Spacer() {
  return <div className="grow"></div>;
}

export { Spacer };
