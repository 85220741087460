import { useTranslation } from "react-i18next";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { LockedTransferContentSkeleton } from "../../skeletons/lockedTransfer";

type BlockedPageProp = {
  cause: string;
};

type TextContentProp = BlockedPageProp;

type BanProp = {
  size: number;
  mainAxisAlignment: string;
  color: string;
};

/**
 * Represents the content for the TransferPage when the transfer, which the user is trying to access,
 * is blocked.
 *
 * @returns a JSX element representing content for the TransferPage
 *          when the transfer is blocked.
 */
export default function BlockedPage(blockedPageProp: BlockedPageProp) {
  return (
    <LockedTransferContentSkeleton>
      <Ban mainAxisAlignment="justify-self-center" size={400} color="#F7F7F7" />
      <TextContent cause={blockedPageProp.cause} />
    </LockedTransferContentSkeleton>
  );
}

function TextContent(textContentProp: TextContentProp) {
  const { t } = useTranslation();
  return (
    <div className="absolute mx-14 flex max-w-[760px] flex-col">
      <div className="text-[26px]">{t(`Transfer blocked`)}</div>
      <div className="mt-9 text-base">
        <div className="font-light">{textContentProp.cause}</div>
        <br />
        <div className="font-light">
          {t(`Please contact the sender and ask them to resend the data.`)}
        </div>
      </div>
    </div>
  );
}

function Ban(banProp: BanProp) {
  return (
    <div className={`${banProp.mainAxisAlignment} `}>
      <NotInterestedIcon
        sx={{
          color: `${banProp.color}`,
          fontSize: `${banProp.size}px`,
        }}
      />
    </div>
  );
}
