/**
 * Checks if the passed [input] is a valid url.
 *
 * @param input
 * @returns true if [input] is a valid url, else returns false.
 */
export default function isURL(input: string): boolean {
  try {
    const url = new URL(input);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (error) {
    return false;
  }
}
