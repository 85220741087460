import { useEffect, useState } from "react";

// This hook listens for theme updates from the parent window and triggers a re-render when the theme is updated.
function useSkpTheme() {
  const [, setThemeUpdatedCount] = useState<number>(0);

  useEffect(() => {
    function handleThemeUpdated(e: MessageEvent) {
      if (e.origin !== window.location.origin) return;
      if (e.data.type !== "SKP_THEME_UPDATED") return;
      setThemeUpdatedCount((prev) => prev + 1);
    }

    window.addEventListener("message", handleThemeUpdated);

    return () => {
      window.removeEventListener("message", handleThemeUpdated);
    };
  }, []);
}

export default useSkpTheme;
