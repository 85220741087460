import { useTranslation } from "react-i18next";
import { Environment } from "../entities";
import { useEnvironment } from "../providers/environment";
import { TagAndValue, extractNonNestedTags } from "../utils/tagParser";

/**
 * Consists of a footer text.
 * @returns a JSX element representing the footer of the page.
 */
export const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: "var(--footer-bg-color)",
        color: "var(--footer-text-color)",
      }}
      className="flex h-[90px] w-full flex-row items-center justify-center text-[10px] text-[#747474]"
    >
      <Content />
    </div>
  );
};

const Content = () => {
  const environment: Environment = useEnvironment()!;

  return (
    <div className="flex flex-col items-center">
      <ProductNameAndProvider />
      <div className="flex flex-row items-center justify-center">
        {!environment.legalNoticeUrl ? (
          <></>
        ) : (
          <>
            <LegalNotice />
            &nbsp;
          </>
        )}

        {environment.legalNoticeUrl && environment.privacyStatementUrl && (
          <>|&nbsp;</>
        )}
        {!environment.privacyStatementUrl ? <></> : <PrivacyPolicy />}
      </div>
      <Timezone />
    </div>
  );
};

/**
 * Contains information about the product name(Teambeam) & product provider(Skalio).
 * @returns a JSX element representing product name and provider information.
 */
const ProductNameAndProvider = () => {
  const { t } = useTranslation();
  const environment: Environment = useEnvironment()!;
  const productNameAndProviderText: string = t(
    `<p>{{productName}}</p> is a product of <c>{{providerName}}</c>`,
    {
      productName: environment.product_name,
      providerName: environment.provider_name,
    }
  );

  const extractedTagsAndValues: TagAndValue[] = extractNonNestedTags(
    productNameAndProviderText
  );

  const elements = getJSXElementsFromExtractedTagAndValue(
    extractedTagsAndValues,
    environment
  );

  return <div>{elements.map((e) => e)}</div>;
};

/**
 * Contains a hyperlink for privacy policy.
 * @returns a hyperlink for privacy policy.
 */
const LegalNotice = () => {
  const { t } = useTranslation();
  const environment: Environment = useEnvironment()!;
  const privacyText: string = t(
    `Responsible for the content: <l>{{customerName}}</l> `,
    { customerName: environment.customerName }
  );

  const extractedTagsAndValues: TagAndValue[] =
    extractNonNestedTags(privacyText);

  const elements = getJSXElementsFromExtractedTagAndValue(
    extractedTagsAndValues,
    environment
  );

  return <div>{elements.map((e) => e)}</div>;
};

/**
 * Contains a hyperlink for privacy policy.
 * @returns a hyperlink for privacy policy.
 */
const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const environment: Environment = useEnvironment()!;
  const privacyText: string = t(`<pp>Privacy Policy</pp>`);

  const extractedTagsAndValues: TagAndValue[] =
    extractNonNestedTags(privacyText);

  const elements = getJSXElementsFromExtractedTagAndValue(
    extractedTagsAndValues,
    environment
  );

  return <div>{elements.map((e) => e)}</div>;
};

/**
 * Represents timezone of the client.
 * @returns a JSX element containing timezone.
 */
const Timezone = () => {
  const { t } = useTranslation();
  const environment = useEnvironment()!;
  const timezoneText: string = t(`Time zone: {{timezone}}`, {
    timezone: `${environment.timeZone}`,
  });
  return <span>{timezoneText}</span>;
};

/**
 * Utility function for obtaining JSX elements from extracted tags.
 * @returns a JSX element containing timezone.
 */
const getJSXElementsFromExtractedTagAndValue = (
  extractedTagsAndValues: TagAndValue[],
  environment: Environment
) => {
  return extractedTagsAndValues.map((extractedTagAndValue, index) => {
    switch (extractedTagAndValue.tag) {
      case "p":
        return (
          <span key={index}>
            <a
              className="no-underline hover:underline"
              href={environment.product_url.toString()}
              target="_blank"
              rel="noreferrer"
              style={{ color: "var(--footer-link-color)" }}
            >
              {extractedTagAndValue.value}
            </a>
          </span>
        );
      case "c":
        return (
          <span key={index}>
            <a
              className="no-underline hover:underline"
              href={environment.provider_url.toString()}
              target="_blank"
              rel="noreferrer"
              style={{ color: "var(--footer-link-color)" }}
            >
              {extractedTagAndValue.value}
            </a>
          </span>
        );
      case "l":
        return (
          <span key={index}>
            <a
              className="no-underline hover:underline"
              href={environment.legalNoticeUrl?.toString() ?? ""}
              target="_blank"
              rel="noreferrer"
              style={{ color: "var(--footer-link-color)" }}
            >
              {extractedTagAndValue.value}
            </a>
          </span>
        );
      case "pp":
        return (
          <span key={index}>
            <a
              className="no-underline hover:underline"
              href={environment.privacyStatementUrl?.toString() ?? ""}
              target="_blank"
              rel="noreferrer"
              style={{ color: "var(--footer-link-color)" }}
            >
              {extractedTagAndValue.value}
            </a>
          </span>
        );
      case null:
        return <span key={index}>{extractedTagAndValue.value}</span>;
      default:
        return <></>;
    }
  });
};
