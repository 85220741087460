import { useTranslation } from "react-i18next";
import {
  useTransferDraft,
  useTransferDraftDispatch,
} from "../../../../providers/transferDraft";
import { TextField } from "@mui/material";
import { TransferDraftErrors } from "../../../../entities";
import { TransferDraftActionType } from "../../../../reducers/transferDraft";

export default function EmailTextField() {
  const transferDraft = useTransferDraft();
  const transferDraftDispatch = useTransferDraftDispatch();
  const { t } = useTranslation();

  return (
    <TextField
      error={transferDraft.errors.has(TransferDraftErrors.invalidSenderEmail)}
      helperText={
        transferDraft.errors.has(TransferDraftErrors.invalidSenderEmail)
          ? t(`Invalid email address`)
          : undefined
      }
      value={transferDraft.anon.email}
      required
      fullWidth
      variant="filled"
      type="email"
      label={t(`Your email address`)}
      InputProps={{
        disableUnderline: true,
        onBlur: (prop) =>
          transferDraftDispatch({
            type: TransferDraftActionType.senderEmailValidate,
            inputtedSenderEmail: prop.target.value,
          }),
      }}
      // TODO this is not triggered when auto-filling
      onChange={(newValue) => {
        transferDraftDispatch({
          type: TransferDraftActionType.senderEmailUpdate,
          updatedEmail: newValue.target.value,
        });
      }}
    />
  );
}
