import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faUserShield } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@mui/material/Button/Button";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { i18n } from "i18next";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { fetchEnvironment, isLegacyUser, isSkalioIdUser } from "../../../api";
import { Environment } from "../../../entities";
import { hostname } from "../../../utils/environment";
import ErrorPage from "../../error";
import { LockedTransferContentSkeleton } from "../../skeletons/lockedTransfer";
import NotFoundPage from "../notFound";

type RecipientAuthenticatedPageProp = {
  recipientEmail?: string;
  senderEmail?: string;
  recipientId?: string;
};

type ContentProp = RecipientAuthenticatedPageProp & {
  recipientType: RecipientType;
};

type UserShieldIconProp = {
  mainAxisAlignment: string;
  color: string;
};

type ActionButtonProp = {
  onClick: () => void;
  children: ReactNode;
};

type HandleActionButtonClickProp = {
  recipientId?: string;
  recipientType: RecipientType;
  senderEmail?: string;
  recipientEmail?: string;
  i18n: i18n;
};

enum RecipientType {
  // Recipient is an exisisting user of Teambeam.
  exisitingUser,

  // Recipient is a not a Teambeam user yet and
  // can self-signup via skp-client.
  nonExisitingUserAndCanSignup,

  // Recipient is a not a Teambeam user and unfortunately,
  // can't self-signup via skp-client.
  nonExisitingUserButCantSignup,
}

/**
 * Represents the content of the transfer page when the received transfer is recipient-authenticated.
 *
 * @param recipientAuthenticatedPageProp
 * @returns a JSX element representing the UI for recipient-authenticated transfer.
 */
export const RecipientAuthenticatedPage = (
  recipientAuthenticatedPageProp: RecipientAuthenticatedPageProp
) => {
  const { status, data, error } = useQuery({
    retry: false,
    queryKey: ["environment"],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const isLegacyUser1: boolean = await isLegacyUser(
        recipientAuthenticatedPageProp.recipientEmail ?? ""
      );
      const isSkalioIdUser1: boolean = await isSkalioIdUser(
        recipientAuthenticatedPageProp.recipientEmail ?? ""
      );
      const isRecipientExisitingUser: boolean =
        isLegacyUser1 || isSkalioIdUser1;

      if (isRecipientExisitingUser) {
        return RecipientType.exisitingUser;
      }

      const environment: Environment = await fetchEnvironment();

      return environment.self_register_enabled
        ? RecipientType.nonExisitingUserAndCanSignup
        : RecipientType.nonExisitingUserButCantSignup;
    },
  });

  switch (status) {
    case "loading":
      return (
        <CircularProgress
          size={115}
          sx={{
            ".MuiCircularProgress-circleIndeterminate": {
              stroke: "#999999",
              strokeWidth: 4,
            },
          }}
        />
      );
    case "error":
      if (error instanceof AxiosError && error.response?.status === 404) {
        return <NotFoundPage />;
      }
      return <ErrorPage errorMessage={(error as Error).message} />;
    case "success":
      return (
        <LockedTransferContentSkeleton>
          <UserShieldIcon
            mainAxisAlignment="justify-self-center"
            color="#F7F7F7"
          />
          <Content
            recipientEmail={recipientAuthenticatedPageProp.recipientEmail}
            senderEmail={recipientAuthenticatedPageProp.senderEmail}
            recipientType={data as RecipientType}
            recipientId={recipientAuthenticatedPageProp.recipientId}
          />
        </LockedTransferContentSkeleton>
      );
  }
};

/**
 * Background icon for the page.
 *
 * @param userShieldIconProp
 * @returns a JSX element representing background icon for the page.
 */
const UserShieldIcon = (userShieldIconProp: UserShieldIconProp) => (
  <FontAwesomeIcon
    icon={faUserShield as IconProp}
    className={`${userShieldIconProp.mainAxisAlignment} h-[300px] sm:h-[400px]`}
    color={userShieldIconProp.color}
  />
);
/**
 * Content of recipient-authenticated transfer page.
 *
 * @param contentProp
 * @returns a JSX element representing the content of the page.
 */
const Content = (contentProp: ContentProp) => {
  const { i18n } = useTranslation();
  const { title, descriptionText1, descriptionText2, actionButtonText } =
    getTexts(contentProp.recipientType, i18n);

  return (
    <div className="absolute mx-14 mt-48 flex max-w-[760px] flex-col sm:mt-10">
      <div className="text-[26px]">{title}</div>
      <div className="mt-9 text-base font-extralight">{descriptionText1}</div>
      <div className="mt-7 flex justify-center text-base">
        {contentProp.recipientEmail}
      </div>
      <div className="mt-7 font-extralight">{descriptionText2}</div>
      <ActionButton
        onClick={() =>
          handleActionButtonClick({
            recipientId: contentProp.recipientId,
            recipientType: contentProp.recipientType,
            senderEmail: contentProp.senderEmail,
            recipientEmail: contentProp.recipientEmail,
            i18n: i18n,
          })
        }
      >
        <div className="font-ibm-plex-sans font-medium">{actionButtonText}</div>
      </ActionButton>
    </div>
  );
};

/**
 * Action button for different scenarios:
 *
 * * Recipient is an exisiting teambeam account holder : Redirect to teambeam login page button
 * * Recipient is not a teambeam account holder : Mailto the sender of the transfer.
 *
 * @param actionButtonProp
 * @returns
 */
const ActionButton = (actionButtonProp: ActionButtonProp) => {
  return (
    <div className="mt-12 flex justify-center">
      <Button
        type="button"
        variant="contained"
        onClick={actionButtonProp.onClick}
        sx={{
          borderRadius: "20px",
          paddingY: "7px",
          paddingX: "20px",
        }}
      >
        {actionButtonProp.children}
      </Button>
    </div>
  );
};

const handleActionButtonClick = (
  handleActionButtonClickProp: HandleActionButtonClickProp
) => {
  switch (handleActionButtonClickProp.recipientType) {
    case RecipientType.exisitingUser:
      window.open(
        `https://${hostname}/transfer/transfers/received/get/${handleActionButtonClickProp.recipientId}`,
        "_blank"
      );
      break;
    case RecipientType.nonExisitingUserAndCanSignup:
      window.open(`https://${hostname}/transfer`, "_blank");
      break;
    case RecipientType.nonExisitingUserButCantSignup:
      const mailSubject: string = handleActionButtonClickProp.i18n.t(
        `Please set up a TeamBeam account for me`
      );
      const mailBody: string = handleActionButtonClickProp.i18n.t(
        `Thank you for the files you sent via TeamBeam. In order to access the files, I need a TeamBeam account for my email address {{email}} on your TeamBeam server {{serverURL}}. This account would have to be set up by you.`,
        {
          email: `${handleActionButtonClickProp.recipientEmail}`,
          serverURL: `${hostname}`,
        }
      );

      window.location.assign(
        `mailto:${handleActionButtonClickProp.senderEmail}?subject=${mailSubject}&body=${mailBody}`
      );
      break;
  }
};

const getTexts = (recipientType: RecipientType, i18n: i18n) => {
  let title, descriptionText1, descriptionText2, actionButtonText: string;
  switch (recipientType) {
    case RecipientType.exisitingUser:
      title = i18n.t(`Increased security level - login required`);
      descriptionText1 = i18n.t(
        `This transfer was sent with an increased security level. Please log in with your TeamBeam account`
      );
      descriptionText2 = i18n.t(`to access the transfer.`);
      actionButtonText = i18n.t(`Go to the TeamBeam login`);
      break;
    case RecipientType.nonExisitingUserAndCanSignup:
      title = i18n.t(`Increased security level - login required`);
      descriptionText1 = i18n.t(
        `The transfer you requested was sent with an increased security level. To retrieve the data, you need a TeamBeam account for your email address`
      );
      descriptionText2 = i18n.t(
        `The account is free of charge and can be canceled at any time.`
      );
      actionButtonText = i18n.t(`Create a free account`);
      break;
    case RecipientType.nonExisitingUserButCantSignup:
      title = i18n.t(`Increased security level - login required`);
      descriptionText1 = i18n.t(
        `The transfer you requested was sent with an increased security level. To retrieve the data, you need a TeamBeam account for your email address`
      );
      descriptionText2 = i18n.t(
        `Please ask the sender of the transfer to create a TeamBeam account for you in order to get access to the transfer.`
      );
      actionButtonText = i18n.t(`Contact sender`);
      break;
  }
  return { title, descriptionText1, descriptionText2, actionButtonText };
};
