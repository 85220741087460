import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { fetchEnvironment, fetchTransfer } from "../../api";
import { Environment, Transfer } from "../../entities";
import {
  ConditionalEnvironmentProvider,
  EnvironmentProvider,
} from "../../providers/environment";
import ErrorPage from "../error";
import TransferPageSkeleton from "../skeletons/transfer";
import BlockedPage from "./locked/blocked";
import PasswordProtectedPage from "./locked/passwordProtected";
import { RecipientAuthenticatedPage } from "./locked/recipientAuthenticated";
import NotFoundPage from "./notFound";
import SuccessPage from "./success";
import LoadingScreen from "../loading";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

type SuccessContentProp = {
  transfer: Transfer;
};

export default function TransferPage() {
  const { recipientId } = useParams();

  // the router should not allow this to happen
  if (!recipientId) throw new Error("recipientId is undefined");

  // This stores / refers to environment data, if fetch successfully,
  // so that NOT-FOUND & BLOCKED transfer GET pages can
  // use environment's values, as per their need, even if fetching transfer fails.
  const environmentRef = useRef<Environment | undefined>(undefined);

  const { t } = useTranslation();
  const { status, data, error } = useQuery({
    retry: false,
    queryKey: ["transferAndEnvironment", recipientId],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let environment: Environment = await fetchEnvironment();

      environmentRef.current = environment;

      let transfer: Transfer = await fetchTransfer(recipientId);

      return { environment, transfer };
    },
  });
  const { isMobileWidth } = useWindowDimensions();
  const childrenVerticalAlignment: string = isMobileWidth ? "start" : "center";

  switch (status) {
    case "loading":
      return <LoadingScreen />;
    case "error":
      if (error instanceof AxiosError && error.response) {
        if (error.response.status === 404) {
          return (
            <ConditionalEnvironmentProvider
              condition={!!environmentRef.current}
              environment={environmentRef.current}
            >
              <TransferPageSkeleton
                childrenVerticalAlignment="center"
                hasMailboxLoaded={false}
              >
                <NotFoundPage />
              </TransferPageSkeleton>
            </ConditionalEnvironmentProvider>
          );
        }

        // Transfer is blocked post first successful download.
        // For more information, visit this url:
        // https://dev.skalio.net/teambeam/features/block-after-first-download/
        if (error.response.status === 403) {
          const cause: string = t(
            `This transfer is not available anymore, because the sender has determined to block it after the first download.`
          );
          return (
            <ConditionalEnvironmentProvider
              condition={!!environmentRef.current}
              environment={environmentRef.current}
            >
              <TransferPageSkeleton
                childrenVerticalAlignment="center"
                hasMailboxLoaded={false}
              >
                <BlockedPage cause={cause} />
              </TransferPageSkeleton>
            </ConditionalEnvironmentProvider>
          );
        }
      }

      return <ErrorPage errorMessage={(error as Error).message} />;
    case "success":
      return (
        <EnvironmentProvider environment={data.environment}>
          <div className={`animate-fade-in`}>
            <TransferPageSkeleton
              childrenVerticalAlignment={childrenVerticalAlignment}
              hasMailboxLoaded={true}
            >
              <SuccessContent transfer={data.transfer} />
            </TransferPageSkeleton>
          </div>
        </EnvironmentProvider>
      );
  }
}

function SuccessContent(successContentProp: SuccessContentProp) {
  const { t } = useTranslation();
  const [unlockedOrBlockedTransfer, setUnlockedOrBlockedTransfer] =
    useState<Transfer | null>(null);

  const transfer = unlockedOrBlockedTransfer ?? successContentProp.transfer;

  if (transfer.isBlocked) {
    const cause: string = t(
      `The transfer you requested is no longer available because the required password was entered incorrectly for the third time.`
    );
    return <BlockedPage cause={cause} />;
  } else if (transfer.isProtected && transfer.isLocked) {
    return (
      <PasswordProtectedPage
        transferRecipientId={transfer.recipientId}
        passwordAttemptsLeft={transfer.passwordAttempts}
        onTransferUnlockOrBLocked={(unlockedOrBlockedTransfer) => {
          setUnlockedOrBlockedTransfer(unlockedOrBlockedTransfer);
        }}
      />
    );
  } else if (transfer.isRecipientAuthenticationEnabled) {
    return (
      <RecipientAuthenticatedPage
        recipientId={transfer.recipientId}
        recipientEmail={successContentProp.transfer.receiver?.email}
        senderEmail={successContentProp.transfer.sender?.email}
      />
    );
  } else {
    return <SuccessPage transfer={transfer} />;
  }
}
