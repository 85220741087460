import { ApiException } from "../entities";

const isApiException = (response: any): response is ApiException => {
  const doesErrorExist: boolean = response.hasOwnProperty("error");
  if (!doesErrorExist) return false;

  const error: Object = response.error;
  if (Object.keys(error).length > 3) return false;

  const doesCodeFieldExist: boolean = error.hasOwnProperty("code");
  const doesMessageFieldExist: boolean = error.hasOwnProperty("message");
  const doesDetailsFieldExist: boolean = error.hasOwnProperty("details");

  return doesCodeFieldExist && doesMessageFieldExist && doesDetailsFieldExist;
};

function assertNever(x: never): never {
  throw new Error("Unexpected object: " + x);
}

export { assertNever, isApiException };

