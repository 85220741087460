import Modal from "@mui/material/Modal/Modal";
import FilePreview from "./filePreview";

type PreviewPageProp = {
  onClose: () => void;
  filePreviewUrl?: URL;
  mimeType?: string;
  fileIndex?: number;
  fileName?: string;
  transferRecipientId?: string;
};

export default function PreviewPage(previewProp: PreviewPageProp) {
  return (
    <Modal open={true} onClose={previewProp.onClose} closeAfterTransition>
      <div className="absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2">
        <FilePreview
          filePreviewUrl={previewProp.filePreviewUrl}
          mimeType={previewProp.mimeType}
          fileIndex={previewProp.fileIndex}
          fileName={previewProp.fileName}
          transferRecipientId={previewProp.transferRecipientId}
        />
      </div>
    </Modal>
  );
}
