import getInitials from "../utils/initials";
import { useRef, useState } from "react";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { getAvatarUri } from "../api";

type UserAvatarProp = {
  uid?: string;
  name?: string;
  size: number;
};

type AvatarProp = UserAvatarProp & {
  isAnonymous: boolean;
};

type LoadingAvatarProp = {
  size: number;
};

type AnonymousAvatarProp = LoadingAvatarProp;

type InitialsProp = LoadingAvatarProp & {
  name: string;
};

type ProfileAvatarProp = LoadingAvatarProp & {
  onLoad: () => void;
  uid?: string;
  name?: string;
};

export default function Avatar(avatarProp: AvatarProp) {
  return avatarProp.isAnonymous ? (
    <AnonymousAvatar size={avatarProp.size} />
  ) : (
    <UserAvatar
      uid={avatarProp.uid}
      name={avatarProp.name}
      size={avatarProp.size}
    />
  );
}

function UserAvatar(userAvatarProp: UserAvatarProp) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // Legacy user
  if (!userAvatarProp.uid)
    return (
      <InitialsAvatar
        name={getInitials(userAvatarProp.name) ?? "--"}
        size={userAvatarProp.size}
      />
    );

  return (
    <>
      <div className={isLoading ? "" : "hidden"}>
        <LoadingAvatar size={userAvatarProp.size} />
      </div>
      <div className={isLoading ? "hidden" : ""}>
        <ProfileAvatar
          uid={userAvatarProp.uid}
          size={userAvatarProp.size}
          name={userAvatarProp.name}
          onLoad={() => {
            setIsLoading(false);
          }}
        />
      </div>
    </>
  );
}

/**
 * Returns the avatar set by the user(sender / recipient).
 * @param recipientAvatarProp
 * @returns a JSX element representing the actual recipient's avatar.
 */
function ProfileAvatar(profileAvatarProp: ProfileAvatarProp) {
  const isProfileAvatarAvailableRef = useRef<boolean>(true);

  const onLoad = () => profileAvatarProp.onLoad();
  const onError = () => {
    isProfileAvatarAvailableRef.current = false;
    profileAvatarProp.onLoad();
  };

  return isProfileAvatarAvailableRef.current ? (
    <img
      className={`aspect-square rounded-full border-[1px] border-white drop-shadow-[0_0px_3px_rgba(0,0,0,0.29)]`}
      width={profileAvatarProp.size}
      height={profileAvatarProp.size}
      src={getAvatarUri(profileAvatarProp.uid ?? "")}
      onLoad={onLoad}
      onError={onError}
      alt={(profileAvatarProp.name ?? "--") + " avatar"}
    />
  ) : (
    <InitialsAvatar
      name={getInitials(profileAvatarProp.name) ?? "--"}
      size={profileAvatarProp.size}
    />
  );
}

/**
 * Returns the avatar with recipient's initials within.
 *
 * @param initialsProp
 * @returns a JSX element representing initials avatar.
 */
function InitialsAvatar(initialsProp: InitialsProp) {
  return (
    <div
      className={`flex aspect-square items-center justify-center rounded-full bg-[#666666]  font-ibm-plex-sans text-xl font-medium text-white drop-shadow-[0_0px_3px_rgba(0,0,0,0.29)]`}
      style={{
        minWidth: initialsProp.size,
        minHeight: initialsProp.size,
      }}
    >
      {initialsProp.name}
    </div>
  );
}

/**
 * Returns a dummy avatar which is shown when avatar is being loaded.
 *
 * @param loadingAvatarProp
 * @returns a JSX element representing loading avatar.
 */
function LoadingAvatar(loadingAvatarProp: LoadingAvatarProp) {
  return (
    <div
      className={`aspect-square animate-pulse rounded-full bg-[#E5E5E6]`}
      style={{
        minWidth: loadingAvatarProp.size,
        minHeight: loadingAvatarProp.size,
      }}
    ></div>
  );
}

/**
 * Returns a custom avatar which is shown when the transfer is sent via mailbox aka skp-light.
 *
 * @param anonymousAvatarProp
 * @returns a JSX element representing anonymous avatar.
 */
function AnonymousAvatar(anonymousAvatarProp: AnonymousAvatarProp) {
  const { isMobileWidth } = useWindowDimensions();
  const svgIconWidth: string = !isMobileWidth ? "42" : "24";
  const svgIconHeight: string = !isMobileWidth ? "26" : "19";

  return (
    <div
      className={`flex aspect-square items-center justify-center rounded-full bg-white p-1 pl-2 drop-shadow-md sm:pl-[10px] `}
      style={{
        minHeight: anonymousAvatarProp.size,
        minWidth: anonymousAvatarProp.size,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={svgIconWidth}
        height={svgIconHeight}
        viewBox="0 0 40 31.077"
      >
        <g
          id="Gruppe_1660"
          data-name="Gruppe 1660"
          transform="translate(-565.935 -175.857)"
        >
          <path
            id="user"
            d="M13.359,15.538a7.7,7.7,0,0,0,7.634-7.769A7.7,7.7,0,0,0,13.359,0,7.7,7.7,0,0,0,5.725,7.769,7.7,7.7,0,0,0,13.359,15.538ZM18.7,17.481h-1a10.217,10.217,0,0,1-8.7,0h-1A8.089,8.089,0,0,0,0,25.638v2.525a2.889,2.889,0,0,0,2.863,2.913H23.855a2.889,2.889,0,0,0,2.863-2.913V25.638A8.089,8.089,0,0,0,18.7,17.481Z"
            transform="translate(565.935 175.857)"
            fill="#999"
          />
          <path
            id="link"
            d="M11.043,6.268a5.137,5.137,0,0,1,.012,7.256l-.012.013L8.771,15.809A5.139,5.139,0,0,1,1.5,8.54L2.757,7.286a.541.541,0,0,1,.923.359,6.23,6.23,0,0,0,.328,1.783.544.544,0,0,1-.128.562l-.443.443A2.435,2.435,0,1,0,6.858,13.9L9.13,11.624a2.434,2.434,0,0,0,0-3.443,2.533,2.533,0,0,0-.35-.29.542.542,0,0,1-.235-.426,1.347,1.347,0,0,1,.4-1.008l.712-.712a.543.543,0,0,1,.7-.059,5.156,5.156,0,0,1,.694.581ZM15.808,1.5a5.145,5.145,0,0,0-7.268,0L6.268,3.775l-.012.013a5.14,5.14,0,0,0,.706,7.837.543.543,0,0,0,.7-.059l.712-.712a1.347,1.347,0,0,0,.4-1.008A.542.542,0,0,0,8.53,9.42a2.533,2.533,0,0,1-.35-.29,2.434,2.434,0,0,1,0-3.443l2.272-2.272A2.435,2.435,0,1,1,13.874,6.88l-.443.443a.544.544,0,0,0-.128.562,6.23,6.23,0,0,1,.328,1.783.541.541,0,0,0,.923.359l1.255-1.255a5.145,5.145,0,0,0,0-7.268Z"
            transform="translate(588.623 176.753)"
            fill="#999"
          />
        </g>
      </svg>
    </div>
  );
}
