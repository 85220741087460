import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { faFilePlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Chip, Tooltip } from "@mui/material";
import { Dispatch } from "react";
import { DropzoneState, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { TransferDraft, TransferDraftErrors } from "../../../entities";
import {
  DropzoneContextProvider,
  useInnerDropzoneState,
} from "../../../providers/dropzoneProvider";
import {
  useOuterDropzoneState,
  useTransferDraft,
  useTransferDraftDispatch,
} from "../../../providers/transferDraft";
import {
  TransferDraftAction,
  TransferDraftActionType,
} from "../../../reducers/transferDraft";
import { TagAndValue, extractNonNestedTags } from "../../../utils/tagParser";
import { getHumanReadableFileSize } from "../../../utils/units";

function DropAndFileChipsZone() {
  const transferDraft: TransferDraft = useTransferDraft();
  const transferDraftDispatch: Dispatch<TransferDraftAction> =
    useTransferDraftDispatch();
  const innerDropZoneState: DropzoneState = useDropzone({
    noDragEventsBubbling: true,
    // so as to disable the opening of native file selector
    noClick: true,
    onDropAccepted: (acceptedFiles, dropEvent) => {
      transferDraftDispatch({
        type: TransferDraftActionType.filesAdd,
        addedFiles: acceptedFiles,
      });
    },
  });

  const isDragAccept: boolean = useOuterDropzoneState().isDragAccept;

  const isFileCountInvalid: boolean = transferDraft.errors.has(
    TransferDraftErrors.invalidFileCount
  );
  const isDropzoneEmpty: boolean = !transferDraft.files.length;

  const isOuterDragAccept: boolean = isDragAccept;
  const isInnerDragAccept: boolean = innerDropZoneState.isDragAccept;

  // `true` if the user is dragging file/s within the dropzone.
  const isDragWithinDropzone = !isOuterDragAccept && isInnerDragAccept;

  // `true` if the user is dragging file/s outside the dropzone.
  const isDragOutsideDropzone = isOuterDragAccept && !isInnerDragAccept;

  var colorBorder: string | undefined;
  var colorBackground: string;
  if (isDragWithinDropzone) {
    colorBorder = "var(--dropzone-on-drag-border-color)";
    colorBackground = "var(--dropzone-on-drag-bg-color)";
  } else if (isFileCountInvalid) {
    colorBorder = "var(--dropzone-error-border-color)";
    colorBackground = "var(--dropzone-error-bg-color)";
  } else if (isDragOutsideDropzone) {
    // tailwind color 'slate-400'
    colorBorder = "rgb(148 163 184)";
    colorBackground = "var(--dropzone-bg-color)";
  } else {
    colorBorder = undefined;
    colorBackground = "var(--dropzone-bg-color)";
  }

  return (
    <DropzoneContextProvider innerDropzoneState={innerDropZoneState}>
      <div
        // theme-depending styles
        style={{
          // text color
          color: "var(--dropzone-text-color)",

          // border color
          ...(colorBorder !== undefined
            ? { borderColor: `${colorBorder}` }
            : {}),

          // background color
          backgroundColor: `${colorBackground}`,
        }}
        className={`rounded-[3px] border-[3px] border-dashed transition duration-300 ${
          isFileCountInvalid
            ? "animate-dropzone-background-color animate-dropzone-border-color"
            : ""
        } ${
          isDropzoneEmpty
            ? "flex items-center justify-center gap-x-5 p-[15px] px-11"
            : "flex-warp p-1"
        } `}
        {...innerDropZoneState.getRootProps()}
      >
        <input {...innerDropZoneState.getInputProps()} />
        {isDropzoneEmpty ? <EmptyDropzoneContent /> : <LoadedDropzoneContent />}
      </div>
    </DropzoneContextProvider>
  );
}

/**
 * Empty Dropzone.
 *
 * @param emptyDropzoneProp
 * @returns a JSX element representing empty dropzone.
 */
const EmptyDropzoneContent = () => (
  <div className="flex flex-col items-center justify-center gap-y-2">
    <UploadFileIcon />
    <TextContent />
  </div>
);

/**
 * Dropzone loaded with files & remove all button.
 *
 * @returns a JSX element representing dropzone with added files.
 */
const LoadedDropzoneContent = () => (
  <>
    <AddedFilesChips />
    <RemoveAllFiles />
  </>
);

/**
 * Added files in material chip form.
 *
 * @returns a JSX element representing added files.
 */
const AddedFilesChips = () => {
  const transferDraft = useTransferDraft();
  const transferDraftDispatch = useTransferDraftDispatch();
  return (
    <div>
      {transferDraft.files.map((file) => (
        <Tooltip title={file[0].name} placement="top">
          <Chip
            key={file[0].id}
            deleteIcon={
              <div className="flex h-[13px] w-[13px] items-center justify-center rounded-full bg-white">
                <FontAwesomeIcon
                  icon={faXmark as IconProp}
                  className="h-[10.5px] w-[10.5px] text-[#1A1A1A]"
                />
              </div>
            }
            label={
              <div className="flex flex-row">
                <div className="truncate">{file[0].name}</div>
                <div>&nbsp;{getHumanReadableFileSize(file[0].size)}</div>
              </div>
            }
            //todo define colors in a central place.
            sx={{ backgroundColor: "#E6E6E6", margin: 0.7 }}
            onDelete={() =>
              transferDraftDispatch({
                type: TransferDraftActionType.filesRemove,
                removedFiles: [file],
              })
            }
          />
        </Tooltip>
      ))}
    </div>
  );
};

/**
 * Removes all added files.
 *
 * @returns a JSX element representing a button to remove all
 * the files added by the sender.
 */
const RemoveAllFiles = () => {
  const { t } = useTranslation();
  const transferDraft = useTransferDraft();
  const transferDraftDispatch = useTransferDraftDispatch();
  return (
    <div className="flex justify-end">
      <Button
        variant="text"
        sx={{
          paddingX: "15px",
          ":hover": { backgroundColor: "#E6E6E6" },
          borderRadius: "10px",
          fontWeight: 400,
        }}
        onClick={() => {
          transferDraftDispatch({
            type: TransferDraftActionType.filesRemove,
            removedFiles: transferDraft.files,
          });
        }}
      >
        <p className="text-[11px]">{t(`Remove all`)}</p>
      </Button>
    </div>
  );
};

/**
 * Upload file icon for [EmptyDropzoneContent] component.
 *
 * @returns a JSX element representing custom upload file icon.
 */
const UploadFileIcon = () => {
  const isDragAccept: boolean = useInnerDropzoneState()!.isDragAccept;
  const transferDraft: TransferDraft = useTransferDraft();
  const hasNoFileAdded: boolean = transferDraft.errors.has(
    TransferDraftErrors.invalidFileCount
  );

  const errorBackgroundColorAnimation = hasNoFileAdded
    ? "animate-dropzone-file-icon-color"
    : "";

  // prettier-ignore
  const iconColor: string = isDragAccept
    ? "var(--dropzone-on-drag-icon-color)"
    : hasNoFileAdded
      ? "var(--dropzone-error-icon-color)"
      : "var(--dropzone-icon-color)";

  return (
    <div
      style={{ backgroundColor: iconColor }}
      className={`${errorBackgroundColorAnimation} flex h-[50px] min-h-[50px] w-[50px] min-w-[50px] items-center justify-center rounded-full transition duration-300`}
    >
      <FontAwesomeIcon
        icon={faFilePlus as IconProp}
        className={` h-[22.22px] w-[16.67px] text-white`}
      />
    </div>
  );
};

/**
 * Text content of [EmptyDropzoneContent] component.
 *
 * @returns a JSX element containing a file picker button.
 */
const TextContent = () => {
  const { t } = useTranslation();
  const innerDropZoneState: DropzoneState = useInnerDropzoneState()!;
  const transferDraft: TransferDraft = useTransferDraft();
  const invalidFileCount: boolean = transferDraft.errors.has(
    TransferDraftErrors.invalidFileCount
  );
  const isDragAccept: boolean = innerDropZoneState.isDragAccept;
  const dropzoneText: string = t(
    `Select <f>files</f> or drag them onto this area`
  );

  const textButtonColor: string =
    invalidFileCount && !isDragAccept
      ? "var(--dropzone-error-file-selector-text-color)"
      : "var(--dropzone-file-selector-text-color)";

  const extractedTagsAndValues: TagAndValue[] =
    extractNonNestedTags(dropzoneText);

  const elements = extractedTagsAndValues.map((extractedTagAndValue, index) => {
    switch (extractedTagAndValue.tag) {
      case "f":
        return (
          <span
            key={index}
            style={{ color: textButtonColor }}
            className={"cursor-pointer font-medium"}
            onClick={innerDropZoneState.open}
          >
            {extractedTagAndValue.value}
          </span>
        );
      case null:
        return (
          <span
            key={index}
            style={{ color: "var(--dropzone-text-color)" }}
            className="text-[15px]"
          >
            {extractedTagAndValue.value}
          </span>
        );
      default:
        return <></>;
    }
  });

  return <div className="text-wrap">{elements.map((e) => e)}</div>;
};

export { DropAndFileChipsZone, AddedFilesChips };
