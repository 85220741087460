import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function LoadingScreen() {
  const { t } = useTranslation();

  return (
    <div className="flex min-h-screen w-screen flex-col items-center justify-center text-xl text-[#747474] p-5 sm:p-16">
      <img className="w-2/3 sm:w-1/3" src="/assets/logos/teambeam.svg" alt="Teambeam Logo"  />
      <div className="h-9" />
      <CircularProgress
        size={100}
        sx={{
          ".MuiCircularProgress-circleIndeterminate": {
            stroke: "#999999",
            strokeWidth: 3,
          },
        }}
      />
      <h4 className="mt-7">{t("Load the mailbox...")}</h4>
    </div>
  );
}
