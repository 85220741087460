import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { CircularProgress, Dialog, Fade, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Environment, TransferDraft } from "../../../entities";
import { useTransferDraft } from "../../../providers/transferDraft";
import { getHumanReadableFileSize } from "../../../utils/units";
import { useEnvironment } from "../../../providers/environment";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import { Spacer } from "../../../components/layout";
import { TransitionProps } from "@mui/material/transitions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type UploadDialogProp = {
  onDismiss: () => void;
  draft: TransferDraft;
  progress: number;
};

type CircularProgressIndicatorProp = {
  progress: number;
};

type GradientCircleProp = {
  isUploadComplete: boolean;
};

type UploadDialogDismissButtonProp = {
  onDismiss: () => void;
};

type ProgressDetailsProp = CircularProgressIndicatorProp;
type UploadedChunkInfoProp = CircularProgressIndicatorProp;
type TitleProp = GradientCircleProp;

const dialogFadeTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Fade ref={ref} {...props} />;
});

// TODO implement ETA, upload abort button & speed for the next iteration.
/**
 * This component represents a dialog which is rendered during an on-going transfer
 * upload.
 *
 * @returns JSX element representing a dialog.
 *
 * @see{@link Dialog}.
 */
export default function UploadDialog({
  onDismiss,
  progress,
}: UploadDialogProp) {
  const { isMobileWidth } = useWindowDimensions();

  return (
    <Dialog
      open={true}
      maxWidth={"md"}
      fullScreen={isMobileWidth}
      fullWidth={true}
      onClose={(event, reason) => {
        // disable backdrop click
        if (progress < 100 && reason === "backdropClick") {
          return null;
        }
        onDismiss();
      }}
      PaperProps={{
        style: { borderRadius: 0 },
      }}
      TransitionComponent={isMobileWidth ? dialogFadeTransition : undefined}
      transitionDuration={500}
    >
      <div className="relative flex h-screen flex-col place-items-center p-10 sm:h-[368px] sm:flex-row sm:gap-x-[50px] sm:p-0">
        {progress >= 100 && (
          <div className="self-end sm:hidden">
            <UploadDialogDismissButton onDismiss={onDismiss} />
          </div>
        )}
        <div className="mt-[15vh] sm:ml-36 sm:mt-0">
          <CircularProgressIndicator progress={progress} />
        </div>
        <div className="h-8 sm:h-0" />
        <ProgressDetails progress={progress} />
        {/*!! Since transfer abort feature is not yet implemented  */}
        {/* {progress >= 100 ? (
          <></>
        ) : (
          <div className="mb-3">
            <UploadAbortButton />
          </div>
        )} */}
      </div>
    </Dialog>
  );
}

/**
 * This component represents a determinate circular progress indicator for
 * the on-going transfer upload with progress value inside of it.
 *
 * @returns JSX element representing a circular progress indicator.
 *
 * @see{@link CircularProgress}.
 */
function CircularProgressIndicator({
  progress,
}: CircularProgressIndicatorProp) {
  const [isUploadComplete, setIsUploadComplete] = useState<boolean>(false);

  useEffect(() => {
    if (progress >= 100 && !isUploadComplete) {
      setIsUploadComplete(true);
    }
  }, [progress, isUploadComplete]);

  return (
    <div className="relative" style={{ strokeDashoffset: 0 }}>
      <div
        className={`absolute h-[180px] w-[180px]  rounded-full bg-[#DEDEDE] ${
          isUploadComplete
            ? "animate-ping-upload-progress-bar"
            : "animate-ping-wave-upload-progress-bar"
        }`}
      ></div>
      <GradientCircle isUploadComplete={progress >= 100} />
      <div className="animate-upload-progress-bar">
        <CircularProgress variant="determinate" value={progress} size={184} />
      </div>
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[40px] font-medium text-[#FEFFFE]">
        {Math.round(progress)}%
      </div>
    </div>
  );
}

/**
 * This component represents a Gradient circle.
 *
 * @returns JSX element representing a circle with linear gradient.
 */
function GradientCircle({ isUploadComplete }: GradientCircleProp) {
  const outerContainerStyle = {
    opacity: isUploadComplete ? 0 : 1,
    backgroundImage: "linear-gradient(to bottom, #F7F7F7, #E6E6E6, #999999)",
    transition: "opacity 1.5s cubic-bezier(0.4, 0, 0.6, 1)",
  };

  const innerContainerStyle = {
    backgroundImage: "var(--upload-dialog-linear-gradient)",
  };

  return (
    <div className="relative flex h-[180px] w-[180px] items-center justify-center rounded-full bg-white drop-shadow-md">
      <div
        className={` h-[160px] w-[160px] rounded-full`}
        style={innerContainerStyle}
      ></div>
      <div
        className={`absolute h-[160px] w-[160px] rounded-full`}
        style={outerContainerStyle}
      ></div>
    </div>
  );
}

/**
 * This component displays details regarding transfer upload.
 *
 * @returns JSX element displaying a upload progress details.
 */
function ProgressDetails({ progress }: ProgressDetailsProp) {
  const { t } = useTranslation();
  const environment: Environment = useEnvironment()!;
  const displaySignupLink: boolean =
    environment.self_register_enabled && !!environment.self_register_url;

  const isUploading: boolean = progress < 100;

  const { isMobileWidth } = useWindowDimensions();

  return (
    <div className="flex h-full flex-col justify-center">
      <div className="h-0 sm:h-16" />
      {!isMobileWidth && <Spacer />}
      <div className="flex flex-row items-center justify-center gap-x-4 sm:justify-start">
        <Title isUploadComplete={progress >= 100} />
        {!isUploading && <CheckCircleIcon />}
      </div>
      {isUploading ? (
        <UploadedChunkInfo progress={progress} />
      ) : (
        <div className="pl-[45px] text-[12px] font-medium text-[#747474] sm:pl-0">
          {t(`Completed`)}
        </div>
      )}
      <Spacer />
      {!isUploading && displaySignupLink && (
        <div className="animate-fade-in">
          <SignupTextAndLink />
        </div>
      )}
      <div className="h-0 sm:h-11" />
    </div>
  );
}

const SignupTextAndLink = () => {
  const environment: Environment = useEnvironment()!;
  const { t } = useTranslation();
  return (
    <span className="flex text-[12px] text-[#747474]">
      {t(`Would you also like to use TeamBeam`)}&nbsp;
      <a
        href={environment.self_register_url!.href}
        target="_blank"
        rel="noreferrer"
        style={{
          color: "var(--footer-link-color)",
          textDecoration: "none",
        }}
      >
        {t(`free of charge?`)}
      </a>
    </span>
  );
};

/**
 * This component represents title of the upload dialog.
 *
 * @returns JSX element displaying title upload dialog's title.
 */
function Title({ isUploadComplete }: TitleProp) {
  const { t } = useTranslation();
  return (
    <div className="text-[26px] font-medium sm:font-normal">
      {isUploadComplete ? t(`Transfer sent`) : t(`Transfer is uploading`)}
    </div>
  );
}

/**
 * This component displays chunks that have been uploaded so far.
 *
 * @returns JSX element displaying uploaded chunks.
 */
function UploadedChunkInfo({ progress }: UploadedChunkInfoProp) {
  const { t } = useTranslation();
  const transferDraft: TransferDraft = useTransferDraft();
  const totalFilesSize = transferDraft.files
    .map((f) => f[1].size)
    .reduce((acc, curr) => acc + curr);
  let uploadedBytes: number = (progress * totalFilesSize) / 100;
  return (
    <div className="text-xs text-[#747474]">
      {getHumanReadableFileSize(uploadedBytes)} {t(`from`)}{" "}
      {getHumanReadableFileSize(totalFilesSize)}
    </div>
  );
}

const UploadDialogDismissButton = ({
  onDismiss,
}: UploadDialogDismissButtonProp) => {
  return (
    <IconButton onClick={(_) => onDismiss()}>
      <FontAwesomeIcon
        icon={faXmark as IconProp}
      />
    </IconButton>
  );
};

//TODO implement it once jagmit's refacroring MR lands in skp-client.
/**
 * This component repesents a button to abort the on-going transfer upload.
 *
 * @returns JSX element representing upload abort button.
 */
// function UploadAbortButton() {
//   return (
//     <IconButton onClick={() => {}}>
//       <CloseIcon
//         sx={{
//           color: "#C40606",
//           width: 18,
//         }}
//       />
//     </IconButton>
//   );
// }
