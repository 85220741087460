/**
 * Returns the initials of recipient's name such that
 * the returned string will have first letters of first name
 * & last name.
 *
 * @param realName Recipient's name.
 * @returns initials of recipient's name.
 */
export default function getInitials(realName?: string): string {
  if (!realName) {
    return "";
  }
  const cleanedName: string = realName.trim().toUpperCase();

  const splittedName: string[] = cleanedName.split(" ");

  const firstName: string[] = [...splittedName[0]];
  const lastName: string[] =
    splittedName.length > 1 ? [...splittedName[splittedName.length - 1]] : [""];

  return firstName[0] + lastName[0];
}
