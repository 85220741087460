import { Environment } from "../entities";
import { useEnvironment } from "../providers/environment";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { Spacer } from "./layout";
import LocaleSwitcher from "./localeSwitcher";

/**
 * Top banner of the page.
 * @returns a JSX element representing the top banner of the page.
 */
export default function TopBanner() {
  const environment: Environment | undefined = useEnvironment();
  const { isMobileWidth } = useWindowDimensions();
  return (
    <div
      style={{
        borderBottomColor: "var(--top-banner-bottom-border-color)",
        backgroundColor: "var(--logo-background-color)",
      }}
      className="flex h-12 flex-row items-center border-b-[1.8px] px-2 sm:h-20 sm:px-5"
    >
      <Logo />
      <Spacer />
      {environment && !isMobileWidth && (
        <OrganizationName organizationName={environment.customerName} />
      )}
      <div className="w-4 sm:w-11" />
      <div className="items-center justify-end">
        <LocaleSwitcher />
      </div>
    </div>
  );
}

const Logo = () => (
  <div
    style={{ backgroundImage: "var(--logo-url)" }}
    className="flex h-9 w-36 flex-col justify-center bg-contain bg-left bg-no-repeat sm:h-14 sm:w-52"
  />
);

type OrganizationNameProp = {
  organizationName: string;
};

const OrganizationName = ({ organizationName }: OrganizationNameProp) => (
  <div
    className="text-sm"
    style={{
      color: "var(--on-logo-background-color)",
    }}
  >
    {organizationName}
  </div>
);
