import axios from "axios";
import {
  confirmReservation,
  createMailboxReservation,
  createUploadFormData,
  getUploadedFileSize,
  uploadFileChunk,
} from "../api";
import {
  MailboxReservationRequest,
  ProtectionType,
  PublicboxDetails,
  Reservation,
  SkpReservedFile,
  SkpUploadInfo,
  TransferDraft,
} from "../entities";
import delay from "../utils/delay";
import { constants } from "../utils/constants";

// TODO set proper value and maybe even adjust to network condition or user-agent

// ===================================================================================

type UploadData = {
  reservedFile: SkpReservedFile;
  file: File;
  startByte: number;
  endByte?: number;
  formData?: FormData;
};

type InitiateUploadProp = {
  currentChunkSize: number;
  shouldCheckUploadedFileSize: boolean;
  uploadData: UploadData;
  reservationToken: string;
  retryCount: number;
  pushProgress: (progress: number, startByte: number) => void;
};

type RetryUploadProp = InitiateUploadProp;

// ===================================================================================

export default async function uploadMailboxTransfer(
  draft: TransferDraft,
  publicboxDetails: PublicboxDetails,
  onProgress: (progress: number) => void
): Promise<void> {
  const totalFilesSize = draft.files.reduce(
    (acc, file) => acc + file[0].size,
    0
  );
  onProgress(0);
  var completedUploadSize = 0;
  var currentUploadSize = 0;

  const pushProgress = (uploadedBytes: number, startByte: number) => {
    currentUploadSize = completedUploadSize + startByte + uploadedBytes;
    let uploadProgress: number = Math.floor(
      (currentUploadSize / totalFilesSize) * 100
    );

    // Since, additional bytes of form data is also reported by axios
    // & this additional bytes are reported with different values in different browsers (400 - 500 bytes).
    // There's no reliable way of computing these additional bytes.
    // Therefore for small files (< 50Kb) [uploadProgress] exceeds 100%.
    //
    // Hence, this is done to clip the uploadProgress to not exceed 100%.
    uploadProgress = Math.min(uploadProgress, 100);

    onProgress(uploadProgress);
  };

  // prepare reservation data
  const reservationRequest: MailboxReservationRequest = {
    publicBoxIdx: publicboxDetails.idx,
    anon: draft.anon,
    subject: draft.subject,
    description: draft.description,
    receivers: draft.receivers,
    files: draft.files.map((f) => f[0]),
    deliveryNotification: draft.acknowledgement,
    priority: draft.priority,
    ttl: draft.ttl,
    recipientAuthentication:
      draft.protectionType ===
      (Object.values(ProtectionType)[2] as ProtectionType),
    protection: {
      enabled:
        draft.protectionType ===
        (Object.values(ProtectionType)[1] as ProtectionType),
      key:
        draft.protectionType ===
        (Object.values(ProtectionType)[1] as ProtectionType)
          ? draft.password
          : undefined,
    },
  };

  // make reservation
  var reservation: Reservation = await createMailboxReservation(
    reservationRequest
  );

  let uploadDataList: UploadData[] = draft.files.map<UploadData>((file) => {
    const reservedFile = reservation.files.find(
      (reservedFile) => reservedFile.id === file[0].id
    )!;
    const uploadData: UploadData = {
      reservedFile: reservedFile,
      file: file[1],
      startByte: 0,
    };
    return uploadData;
  });

  // Initiate upload.
  for (const uploadData of uploadDataList) {
    await initiateUpload({
      currentChunkSize: constants.initialChunkSize,
      uploadData: uploadData,
      reservationToken: reservation.token,
      shouldCheckUploadedFileSize: false,
      retryCount: 0,
      pushProgress: pushProgress,
    });
    completedUploadSize += uploadData.reservedFile.size;
  }

  // Confirm reservation.
  await confirmReservation(reservation.token);
}

/**
 *  Initiates the upload process for uploadData, passed as {@link InitiateUploadProp}.
 *
 *  If transfer upload is interupted then it will retry uploading transfer
 *  upto {@link MAX_RETRIES} times. If it still fails, to upload, then it will
 *  abort the upload & throw error.
 *
 * @param initiateUploadProp
 * @returns
 */
async function initiateUpload(
  initiateUploadProp: InitiateUploadProp
): Promise<void> {
  if (initiateUploadProp.shouldCheckUploadedFileSize) {
    // Check how many bytes of file are already uploaded on the server.
    try {
      initiateUploadProp.uploadData.startByte = await getUploadedFileSize(
        initiateUploadProp.uploadData.reservedFile.objectId,
        initiateUploadProp.reservationToken
      );
    } catch (error) {
      if (initiateUploadProp.retryCount >= constants.maxUploadRetries) {
        throw error;
      } else {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          // File was never uploaded on the server.
          initiateUploadProp.uploadData.startByte = 0;
        } else {
          return await retryUpload(initiateUploadProp);
        }
      }
    }
  }

  // Compute end byte for uploadData.
  initiateUploadProp.uploadData.endByte = Math.min(
    initiateUploadProp.uploadData.startByte +
      initiateUploadProp.currentChunkSize,
    initiateUploadProp.uploadData.reservedFile.size
  );

  // Create form data for uploadData.
  initiateUploadProp.uploadData.formData = createUploadFormData({
    blob: initiateUploadProp.uploadData.file.slice(
      initiateUploadProp.uploadData.startByte,
      initiateUploadProp.uploadData.endByte
    ),
    objectId: initiateUploadProp.uploadData.reservedFile.objectId,
    reservationToken: initiateUploadProp.reservationToken,
  });

  // Actual upload of file chunk.
  try {
    const chunkUploadStartTime: number = Date.now();
    const uploadFileChunkResponse: SkpUploadInfo = await uploadFileChunk({
      uploadData: initiateUploadProp.uploadData,
      totalBytes: initiateUploadProp.uploadData.reservedFile.size,
      onUploadProgress: (progressEvent) => {
        initiateUploadProp.pushProgress(
          progressEvent.loaded,
          initiateUploadProp.uploadData.startByte
        );
      },
    });

    // Reset retry counter.
    initiateUploadProp.retryCount = 0;

    // Time (in seconds) took to upload one chunk
    const chunkUploadDuration: number = Math.floor(
      (Date.now() - chunkUploadStartTime) / 1000
    );

    const newChunkSize = calculateAndGetNewChunkSize(
      chunkUploadDuration,
      initiateUploadProp.currentChunkSize
    );

    // Check if all the bytes of current file has been uploaded.
    if (
      uploadFileChunkResponse.size <
      initiateUploadProp.uploadData.reservedFile.size
    ) {
      // There are still some bytes left to be uploaded.
      initiateUploadProp.uploadData.startByte = uploadFileChunkResponse.size;

      return await initiateUpload({
        currentChunkSize: newChunkSize,
        shouldCheckUploadedFileSize: false,
        reservationToken: initiateUploadProp.reservationToken,
        uploadData: initiateUploadProp.uploadData,
        retryCount: initiateUploadProp.retryCount,
        pushProgress: initiateUploadProp.pushProgress,
      });
    }
  } catch (error) {
    if (initiateUploadProp.retryCount >= constants.maxUploadRetries) {
      throw error;
    } else {
      return await retryUpload(initiateUploadProp);
    }
  }
}

async function retryUpload(retryUploadProp: RetryUploadProp): Promise<void> {
  retryUploadProp.retryCount++;

  const waitingTimeMs = Math.min(
    2 ** retryUploadProp.retryCount * 1000,
    constants.maxDelayBetweenRetriesMs
  );

  // Wait for [sleepTimeMs] seconds before retrying chunk upload again.
  await delay(waitingTimeMs);

  retryUploadProp.uploadData.startByte = 0;
  retryUploadProp.shouldCheckUploadedFileSize = true;

  // Retry chunk upload again.
  return await initiateUpload(retryUploadProp);
}

/**
 * Returns new chunk size based on time it took to upload a chunk.
 *
 * @param chunkUploadDuration
 */
const calculateAndGetNewChunkSize = (
  chunkUploadDuration: number, // seconds
  currentChunkSize: number
) => {
  const chunkFactor: number =
    constants.idealChunkUploadDuration / chunkUploadDuration;

  const optimalChunkSize: number = Math.round(currentChunkSize * chunkFactor);

  const clampedChunkSize: number = Math.min(
    optimalChunkSize,
    constants.maxChunkSize
  );

  return clampedChunkSize;
};

export type { UploadData };
