import ScheduleIcon from "@mui/icons-material/Schedule";
import { useTranslation } from "react-i18next";
import { LockedTransferContentSkeleton } from "../skeletons/lockedTransfer";

type ClockProp = {
  size: number;
  mainAxisAlignment: string;
  color: string;
};

// TODO need to confirm text with sonja

/**
 * Represents the content for the TransferPage when either the
 * transfer, which the user is trying to access, does not exist or
 * the transfer is expired.
 *
 * @returns a JSX element representing content for the TransferPage
 *          when the reserver gives 404 response.
 */
export default function NotFoundPage() {
  return (
    <LockedTransferContentSkeleton>
      <Clock
        mainAxisAlignment="justify-self-center"
        size={400}
        color="#F7F7F7"
      />
      <TextContent />
    </LockedTransferContentSkeleton>
  );
}

function TextContent() {
  const { t } = useTranslation();
  return (
    <div className="absolute mx-14 flex max-w-[760px] flex-col">
      <div className="text-[26px]">{t(`Transfer not found`)}</div>
      <div className="mt-9 text-base">
        <div className="font-light">
          {t(
            `The transfer you're looking for is not available because either the transfer does not exist or the validity period of the transfer has been exceeded.`
          )}
        </div>
        <br />
        <div className="font-light">
          {t(
            `In the latter case, the transfer and all the files it contained were deleted in accordance with data protection regulations.`
          )}
        </div>
        <br />
        <div>
          {t(
            `Please make sure that the URL is correct, otherwise contact the sender so that they can provide you with the data again.`
          )}
        </div>
      </div>
    </div>
  );
}

function Clock(clockProp: ClockProp) {
  return (
    <div className={`${clockProp.mainAxisAlignment} `}>
      <ScheduleIcon
        sx={{
          color: `${clockProp.color}`,
          fontSize: `${clockProp.size}px`,
        }}
      />
    </div>
  );
}
