import { ReactNode } from "react";
import { Footer } from "../../components/footer";
import { Spacer } from "../../components/layout";
import TopBanner from "../../components/topBanner";
import { useOuterDropzoneState } from "../../providers/transferDraft";

type MailboxPageSkeletonProp = {
  // Content of [Mailbox] page.
  children: ReactNode;
  childrenVerticalAlignment: string;
  hasMailboxLoaded: boolean;
};

/**
 * Layout skeleton for [Mailbox] page.
 *
 * @param {MailboxPageSkeletonPro} mailboxPageSkeletonPro
 *
 * @returns a JSX element represeting [NotFoundPageSkeleton].
 */
export default function MailboxPageSkeleton(
  mailboxPageSkeletonProp: MailboxPageSkeletonProp
) {
  const outerDropzoneState = useOuterDropzoneState();
  const shouldChildrenBeCentered: boolean =
    mailboxPageSkeletonProp.childrenVerticalAlignment === "center";
  const normalClassName: string = "mx-auto mb-3 w-full max-w-7xl p-7";
  const lolClassName: string = "flex grow items-center justify-center";
  const childrenClassName: string = shouldChildrenBeCentered
    ? lolClassName
    : normalClassName;
  return (
    <div
      style={{ backgroundColor: "var(--page-bg-color)" }}
      className="flex min-h-screen w-full flex-col"
      {...outerDropzoneState.getRootProps()}
    >
      <input {...outerDropzoneState.getInputProps()} />
      <TopBanner />
      <div className={childrenClassName}>
        {mailboxPageSkeletonProp.children}
      </div>
      {!shouldChildrenBeCentered ? <Spacer /> : <></>}
      {mailboxPageSkeletonProp.hasMailboxLoaded && <Footer />}
    </div>
  );
}
