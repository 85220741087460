import { Button, DialogContent } from "@mui/material";
import Dialog from "@mui/material/Dialog/Dialog";
import { useTranslation } from "react-i18next";

type ErrorDialogProp = {
  title: string;
  description: string;
  onDismiss: () => void;
};

export default function MailboxExceptionDialog({
  title,
  description,
  onDismiss,
}: ErrorDialogProp) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={true}
      maxWidth={"md"}
      onClose={(event, reason) => {
        onDismiss();
      }}
    >
      <div className="mx-[80px] my-[70px]">
        <DialogContent>
          <div className="flex flex-col">
            <div
              style={{ color: "var(--primary-color)" }}
              className="mb-[30px] text-[20px]"
            >
              {title}
            </div>
            <div className="mb-[64px] text-[15px] text-[#1A1A1A]">
              {description}
            </div>
            <div className="self-end">
              <Button
                type="button"
                variant="contained"
                onClick={onDismiss}
                sx={{
                  borderRadius: 10,
                }}
              >
                {t(`Close`)}
              </Button>
            </div>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
}
