import axios, { AxiosError, AxiosProgressEvent } from "axios";
import {
  Environment,
  MailboxReservationRequest,
  PublicProfile,
  PublicboxDetails,
  PublicboxInfo,
  Reservation,
  SkpUploadInfo,
  Transfer,
} from "./entities";
import { UploadData } from "./services/transferUpload";
import { transformToDateAndUrlType } from "./utils/axios";
import { basePath, hostname, skalioIDBasePath } from "./utils/environment";

const skpServerBaseURL: string = `https://${hostname}${basePath}/`;
const getSkpServerBaseURL = () => skpServerBaseURL;

const skalioIdBaseURL: string = `https://${hostname}${skalioIDBasePath}/`;
const getSkalioIdBaseURL = () => skalioIdBaseURL;

const apiClient = axios.create({
  baseURL: skpServerBaseURL,
});

const apiClientWithInterceptor = apiClient;

const apiClientForSkalioId = axios.create({
  baseURL: skalioIdBaseURL,
});

//! This axios instance must be used when response data contains timestamp,
//! so as to transform string type timestamp to Date type via interceptor function.
apiClientWithInterceptor.interceptors.response.use(transformToDateAndUrlType);

// ===================================================================================

type UploadFileChunkProp = {
  uploadData: UploadData;
  totalBytes?: number;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
};

type CreateUploadFormDataProp = {
  objectId: string;
  reservationToken: string;
  blob: Blob;
};

type DownloadFileProp = {
  transferRecipientId?: string;
  fileIndex?: number;
  fileName?: string;
};

type PreviewFileProp = {
  transferRecipientId?: string;
  fileIndex?: number;
};

type UnlockTransferProp = {
  transferRecipientId?: string;
  userInputPassword: string;
};

// ===================================================================================

function resolveVanityLink(vanityLink: string): Promise<PublicboxInfo> {
  return apiClient
    .get(`/vanity/${vanityLink}`)
    .then((response) => response.data);
}

function fetchPublicboxDetails(
  publicboxKey: String,
  publicboxIdx: String
): Promise<PublicboxDetails> {
  return apiClientWithInterceptor
    .get(`/publicboxes/${publicboxIdx}`, {
      params: { key: publicboxKey },
    })
    .then((response) => response.data);
}

function fetchEnvironment(): Promise<Environment> {
  return apiClientWithInterceptor
    .get("/environment")
    .then((response) => response.data);
}

function createMailboxReservation(
  request: MailboxReservationRequest
): Promise<Reservation> {
  return apiClient
    .post("/reservations", request)
    .then((response) => response.data);
}

function confirmReservation(reservationToken: string): Promise<void> {
  return apiClient
    .post(`/reservations/${reservationToken}/confirm`)
    .then((response) => response.data);
}

function cancelReservation(reservationToken: string): Promise<void> {
  return apiClient.delete(`/reservations/${reservationToken}`);
}

function createUploadFormData(
  createUploadFormDataProp: CreateUploadFormDataProp
): FormData {
  let formData = new FormData();
  formData.append("objectId", createUploadFormDataProp.objectId);
  formData.append("authToken", createUploadFormDataProp.reservationToken);
  formData.append("f", createUploadFormDataProp.blob);
  return formData;
}

/**
 * Checks & returns the size of the already uploaded reserved file.
 *
 * It will throw 404 exception aka Not found exception, if the
 * reserved file, associated with the objectId, is not yet uploaded on
 * the server.
 *
 * @param objectId  unique id of a reserved file.
 * @param reservationToken token which server returns post reservation creation.
 * @returns a promise of size(in bytes) of the reserved file, with objectId, uploaded on server.
 */
async function getUploadedFileSize(
  objectId: string,
  reservationToken: string
): Promise<number> {
  return apiClient
    .head(`/upload/${objectId}`, {
      headers: {
        "X-Skp-Auth": `${reservationToken}`,
      },
    })
    .then((response) => Number(response.headers["content-length"] ?? "0"));
}

/**
 * Uploads a file in a multipart/form-data format.
 *
 * @param uploadFileChunkProp
 * @returns a promise of {@link SkpUploadInfo}.
 */
async function uploadFileChunk(
  uploadFileChunkProp: UploadFileChunkProp
): Promise<SkpUploadInfo> {
  var contentRange;
  if (
    uploadFileChunkProp.uploadData.startByte !== undefined &&
    uploadFileChunkProp.uploadData.endByte !== undefined &&
    uploadFileChunkProp.totalBytes !== undefined
  ) {
    contentRange = `bytes ${uploadFileChunkProp.uploadData.startByte}-${
      uploadFileChunkProp.uploadData.endByte - 1
    }/${uploadFileChunkProp.totalBytes}`;
  }
  return apiClient
    .post("/upload", uploadFileChunkProp.uploadData.formData, {
      headers: !contentRange
        ? undefined
        : {
            "Content-Range": contentRange,
          },
      onUploadProgress: uploadFileChunkProp.onUploadProgress,
    })
    .then((response) => response.data);
}

/**
 * Fetches a specific transfer according to [recipientId].
 *
 * @param recipientId unique recipient id for a transfer.
 * @returns a promise of Transfer object.
 */
// https://dev.skalio.net/teambeam/api/v1/#transfers-resource-transfers-get-1
function fetchTransfer(recipientId: string): Promise<Transfer> {
  return apiClientWithInterceptor
    .get(`/transfers/${recipientId}`)
    .then((response) => response.data);
}

/**
 * Downloads a specific file in a transfer.
 *
 * @param downloadFileProp
 */
function downloadFile(downloadFileProp: DownloadFileProp): void {
  const transferRecipientId = downloadFileProp.transferRecipientId;
  const fileIndex = downloadFileProp.fileIndex;
  const fileName = downloadFileProp.fileName;

  if (
    transferRecipientId === undefined ||
    fileIndex === undefined ||
    fileName === undefined
  ) {
    throw new Error();
  }

  const anchor = document.createElement("a");
  const downloadUrl = new URL(
    `download/${downloadFileProp.transferRecipientId}/${
      downloadFileProp.fileIndex
    }/${encodeURIComponent(downloadFileProp.fileName!)}`,
    skpServerBaseURL
  );
  anchor.href = downloadUrl.href;
  anchor.setAttribute("download", `${downloadFileProp.fileName}`);
  document.body.appendChild(anchor);
  anchor.click();
  anchor.remove();
}

function getPreviewUrl(previewFileProp: PreviewFileProp): URL {
  return new URL(
    `preview/${previewFileProp.transferRecipientId}/${previewFileProp.fileIndex}`,
    skpServerBaseURL
  );
}

function getDownloadUrl(downloadFileProp: DownloadFileProp) {
  return new URL(
    `download/${downloadFileProp.transferRecipientId}/${
      downloadFileProp.fileIndex
    }/${encodeURIComponent(downloadFileProp.fileName!)}?embed`,
    skpServerBaseURL
  );
}

/**
 * Unlocks a password-protected transfer.
 *
 * @param unlockTransferProp
 * @returns a promise of [unlocked] transfer.
 */
function unlockTransfer(
  unlockTransferProp: UnlockTransferProp
): Promise<Transfer> {
  return apiClient
    .post(`/transfers/${unlockTransferProp.transferRecipientId}/unlock`, {
      unlockCode: unlockTransferProp.userInputPassword,
    })
    .then((response) => response.data);
}

/**
 * Fetches the public profile of any person whose profile is stored in Skalio ID.
 *
 * @param uid
 * @returns a promise of [PublicProfile].
 */
function fetchPublicProfile(uid: string): Promise<PublicProfile> {
  return apiClientForSkalioId
    .get(`/public/profile/${uid}`)
    .then((response) => response.data);
}

/**
 * Returns the uri for fetching recipient's avatar.
 *
 * @param uid
 * @param height
 * @returns avatar's uri.
 */
function getAvatarUri(uid: string, height?: number): string {
  const heightQuereyParam: string = !height ? `` : `?height=${height}`;
  const url: string = `/public/avatar/${uid}` + heightQuereyParam;

  return apiClientForSkalioId.getUri({
    url: url,
  });
}

async function isLegacyUser(recipientEmail: string): Promise<boolean> {
  try {
    await apiClient.post(`/auth/exists`, {
      address: recipientEmail,
    });
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 404) {
      return false;
    }
    throw error;
  }
  return true;
}

async function isSkalioIdUser(recipientEmail: string): Promise<boolean> {
  try {
    await apiClientForSkalioId.post(`/auth/exists`, {
      address: recipientEmail,
    });
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 404) {
      return false;
    }
    throw error;
  }

  return true;
}

export {
  cancelReservation,
  confirmReservation,
  createMailboxReservation,
  createUploadFormData,
  downloadFile,
  fetchEnvironment,
  fetchPublicProfile,
  fetchPublicboxDetails,
  fetchTransfer,
  getAvatarUri,
  getDownloadUrl,
  getSkpServerBaseURL as getLegacyBaseURL,
  getPreviewUrl,
  getSkalioIdBaseURL,
  getUploadedFileSize,
  isLegacyUser,
  isSkalioIdUser,
  resolveVanityLink,
  unlockTransfer,
  uploadFileChunk,
};
