// Returns true, if the environment is production.
const isProduction: boolean =
  process.env.NODE_ENV && process.env.NODE_ENV === "production";

// Returns true, if the environment is development.
const isDevelopment: boolean =
  process.env.NODE_ENV && process.env.NODE_ENV === "development";

const hostname: string = isProduction
  ? window.location.hostname
  : process.env.REACT_APP_DEV_API_HOSTNAME || "future.teambeam.dev";

const basePath: string = process.env.REACT_APP_API_BASEPATH || "/api/skp/v1";

const skalioIDBasePath: string = "/api/id/v3";

export { basePath, hostname, isDevelopment, isProduction, skalioIDBasePath };

