import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import { ReactNode } from "react";
import useSkpTheme from "../../hooks/useSkpTheme";
import { cssVar } from "../../utils/cssVar";

type MuiThemeProviderProp = {
  children: ReactNode;
};

export const MuiThemeProvider = (
  muiThemeProviderProp: MuiThemeProviderProp
) => {
  useSkpTheme();

  const muiTheme = createTheme({
    palette: {
      primary: {
        main: cssVar("--primary-color"),
      },
      warning: {
        main: cssVar("--warning-color"),
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
    components: {
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 14,
          },
        },
      },
      // ============================ Locale Switcher ==================================================================
      // Override styles for the OutlinedInput used within the Select.
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            // Normal state.
            "& fieldset": {
              borderColor: "var(--locale-switcher-border-color)",
            },
            // Hover state for the fieldset.
            "&:hover fieldset": {
              borderColor:
                "var(--locale-switcher-hover-border-color) !important",
            },
            // Focused state for the fieldset.
            "&.Mui-focused fieldset": {
              borderColor:
                "var(--locale-switcher-hover-border-color) !important",
            },
          },
        },
      },
      // Override styles for the Select component.
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: "var(--locale-switcher-text-color)",
          },
          select: {
            color: "var(--locale-switcher-text-color)",
            fontSize: "var(--locale-switcher-font-size)",
            fontWeight: "bold",
            "&:hover": {
              filter: "brightness(0.85)",
            },
          },
        },
      },
      // ================================================================================================================

      // ============================ Buttons ===========================================================================
      MuiButton: {
        styleOverrides: {
          outlined: {
            borderRadius: 20,
          },
          // Override text variant of Mui button [OptionsButton].
          text: {
            color: "var(--options-button-text-color)", // Color for text inside button.
            fontSize: "var(--options-button-text-font-size)", // Font size for text inside button.
          },
          // Override contained variant of Mui button [SendButton].
          contained: {
            color: "white",
            borderRadius: "var(--send-button-border-radius)",
            paddingY: "var(--send-button-vertical-padding)",
            paddingX: "var(--send-button-horizontal-padding)",
            textTransform: "none",
            ":hover": {
              backgroundColor: "var(--send-button-hover-bg-color)",
              filter: "brightness(0.95)",
            },
          },
        },
      },
      // ================================================================================================================
      // ============================ Text Field ========================================================================
      // Override text variant of Mui TextField.
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: "#F7F7F7",
            borderRadius: "3px",
          },
          adornedEnd: {
            backgroundColor: "#F7F7F7",
          },
        },
      },
      // ================================================================================================================
    },
  });

  return (
    <ThemeProvider theme={muiTheme}>
      {muiThemeProviderProp.children}
    </ThemeProvider>
  );
};
