import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import { resolveVanityLink } from "./api";
import "./global.css";
import i18n from "./i18n/config";
import "./index.css";
import DebugPage from "./pages/Debug";
import NotFoundPage from "./pages/NotFound";
import Root from "./pages/Root";
import Mailbox from "./pages/mailbox/Mailbox";
import TransferPage from "./pages/transfer/Transfer";
import { MuiThemeProvider } from "./providers/muiThemeProvider/muiThemeProvider";
import reportWebVitals from "./reportWebVitals";

const router = createBrowserRouter([
  {
    path: "/",
    element: <DebugPage />,
  },
  {
    path: "/",
    element: <Root />,
    hasErrorBoundary: true,
    errorElement: <NotFoundPage />,
    children: [
      //==================================================================================================
      //                                    Legacy routes
      //==================================================================================================
      {
        // mailbox route with publicIdx & publicKey, eg. -> {hostname}/12345/67890/public.html
        path: "/:publicboxIdx/:publicboxKey/public.html",
        element: <Mailbox />,
        loader: ({ params }) => {
          const { publicboxIdx, publicboxKey } = params;
          return redirect(
            `/mailbox/${publicboxKey}/${publicboxIdx}/public.html`
          );
        },
      },
      // mailbox route vanity, eg. -> {hostname}/fooBar
      {
        path: "/:maybeVanityName",
        loader: async ({ params }) => {
          const { maybeVanityName } = params;
          try {
            await resolveVanityLink(maybeVanityName!);
            // confirmed, that maybeVanityName is a vanity name
            return redirect(`/mailbox/${maybeVanityName}`);
          } catch (error) {
            // maybeVanityName is not a vanity name, render regular 404 page
            return undefined;
          }
        },
        element: <NotFoundPage />,
      },
      // transfer GET page route, eg. -> {hostname}/transfer/get/{recipientId}
      {
        path: "/my/transfers/:recipientId",
        element: <TransferPage />,
        loader: ({ params }) => {
          const { recipientId } = params;
          return redirect(`/transfer/get/${recipientId}`);
        },
      },
      //==================================================================================================
      //==================================================================================================
      //
      //
      //==================================================================================================
      //                                  Next Gen Routes
      //==================================================================================================
      {
        path: "/mailbox",
        children: [
          // mailbox route with publicIdx & publicKey, eg. -> {hostname}/mailbox/12345/67890/public.html
          {
            path: ":publicboxIdx/:publicboxKey/public.html",
            element: <Mailbox />,
          },
          // mailbox route vanity, eg. -> {hostname}/mailbox/fooBar
          {
            path: ":vanityName",
            element: <Mailbox />,
          },
        ],
        element: <Mailbox />,
      },
      // transfer GET page route, eg. -> {hostname}/transfer/{recipientId}
      {
        path: "/transfer/get/:recipientId",
        element: <TransferPage />,
      },
      //==================================================================================================
      //==================================================================================================
      //
      //
      // catch all error 404 page
      {
        path: "/*",
        element: <NotFoundPage />,
      },
    ],
  },
]);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider>
        <I18nextProvider i18n={i18n}>
          <RouterProvider router={router} />
        </I18nextProvider>
      </MuiThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
