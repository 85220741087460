import { ReactNode } from "react";

type NotFoundPageSkeletonProp = {
  // Content of [NotFoundPage].
  children: ReactNode;
};

type DisconnectedPlugProp = {
  // Alignment of this component along its container's cross axis.
  crossAxisAlignment: string;
};

/**
 * Layout skeleton for [NotFoundPage].
 *
 * @param {NotFoundPageSkeletonProp} notFoundPageSkeletonProp
 *
 * @returns a JSX element represeting [NotFoundPageSkeleton].
 */
export default function NotFoundPageSkeleton(
  notFoundPageSkeletonProp: NotFoundPageSkeletonProp
) {
  return (
    <div className="flex h-screen w-screen flex-col">
      <DisconnectedPlug crossAxisAlignment="self-end" />
      {notFoundPageSkeletonProp.children}
    </div>
  );
}

/**
 * DisconnectedPlug Image.
 *
 * @param {DisconnectedPlugProp} disconnectedPlugProp
 *
 * @returns a JSX element representing disconnected image.
 */
function DisconnectedPlug(disconnectedPlugProp: DisconnectedPlugProp) {
  return (
    <img
      className={`${disconnectedPlugProp.crossAxisAlignment} h-screen`}
      src={process.env.PUBLIC_URL + "/assets/disconnected-plug.png"}
      alt=""
    />
  );
}
