/**
 * Transforms `bytes` to an appropriate higher unit with the unit's symbol
 * concatenated to it. If transformation to higher unit is not possible then
 * it returns untransformed bytes along with it's symbol concatenated to it.
 *
 * @param bytes - input number that needs to be transformed to appropriate unit.
 * @returns transformed `bytes` with its unit concatenated to it.
 */
function getHumanReadableFileSize(bytes: number): string {
  if (bytes < 1000) {
    return `${bytes}  B`;
  } else if (bytes < 1000 * 1000) {
    return ` ${(bytes / 1000).toFixed(2)} KB`;
  } else if (bytes < 1000 * 1000 * 1000) {
    return `${(bytes / (1000 * 1000)).toFixed(2)} MB`;
  } else if (bytes < 1000 * 1000 * 1000 * 1000) {
    return `${(bytes / (1000 * 1000 * 1000)).toFixed(2)} GB`;
  } else {
    return `${(bytes / (1000 * 1000 * 1000 * 1000)).toFixed(2)}`;
  }
}

/**
 * Prepends `0` to transfer expiration month only if the month
 * can be represented by a single digit.
 *
 * @param expirationMonthIndex
 * @returns padded expiration month in string.
 */
const padTransferExpirationMonth = (expirationMonthIndex?: number) => {
  // `expirationMonthIndex` can be zero only if the month is january.
  // Since, Falsy value include `0` as well, therefore, we want to exclude this case.
  if (!expirationMonthIndex && expirationMonthIndex !== 0) {
    return expirationMonthIndex;
  }

  const expirationMonth: number = expirationMonthIndex + 1;

  const padedExpirationMonth = padIfSingleDigit(expirationMonth);

  return padedExpirationMonth;
};

/**
 * Prepends `0` to a digit only if `number` is single digit.
 *
 * @param expirationMonthIndex
 * @returns padded number in string.
 */
const padIfSingleDigit = (number?: number) => {
  if (!number) {
    return number;
  }

  return number < 10 ? `0${number}` : `${number}`;
};

export {
  getHumanReadableFileSize,
  padTransferExpirationMonth,
  padIfSingleDigit,
};
