import { Button } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatch, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "../../../components/spinner";
import {
  ApiException,
  Environment,
  PublicboxDetails,
  TransferDraft,
  TransferDraftErrors,
} from "../../../entities";
import { useEnvironment } from "../../../providers/environment";
import {
  useTransferDraft,
  useTransferDraftDispatch,
} from "../../../providers/transferDraft";
import {
  TransferDraftAction,
  TransferDraftActionType,
} from "../../../reducers/transferDraft";
import uploadMailboxTransfer from "../../../services/transferUpload";
import { validateFormAndReturnErrorIfAny } from "../../../utils/formValidator";
import { isApiException } from "../../../utils/typeGuard";
import MailboxExceptionDialog from "./errorDialog";
import UploadDialog from "./uploadDialog";
import { UploadDialogThemeProvider } from "../../../providers/muiThemeProvider/uploadDialogThemeProvider";

import useWakeLock from "../../../hooks/useWakelock";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";

type SendButtonProp = {
  publicboxDetails: PublicboxDetails;
};

export default function SendButton({ publicboxDetails }: SendButtonProp) {
  const { t } = useTranslation();
  const environment: Environment = useEnvironment()!;
  const transferDraft: TransferDraft = useTransferDraft();
  const transferDraftDispatch: Dispatch<TransferDraftAction> =
    useTransferDraftDispatch();
  const [progress, setProgress] = useState<number>(0);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isMobileWidth } = useWindowDimensions();
  const { requestScreenWakeLock, releaseScreenWakeLock, isWakeLockActive } =
    useWakeLock();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Don't show alert pop up when there's no ongoing transfer.
      if (!isLoading) return;

      // Cancel the event.
      event.preventDefault();

      // Chrome requires returnValue to be set.
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isLoading]);

  const onSendButtonPressed = async () => {
    const postValidationErrors: Set<TransferDraftErrors> =
      validateFormAndReturnErrorIfAny(transferDraft);

    if (postValidationErrors.size || transferDraft.errors.size) {
      if (postValidationErrors) {
        transferDraftDispatch({
          type: TransferDraftActionType.errorUpdate,
          postValidationErrors: postValidationErrors,
        });
      }
      return;
    }

    setIsLoading(true);

    if (!isWakeLockActive) await requestScreenWakeLock();

    try {
      await uploadMailboxTransfer(transferDraft, publicboxDetails, setProgress);
      // clear transfer draft
      transferDraftDispatch({
        type: TransferDraftActionType.reset,
        environment: environment,
      });
    } catch (error) {
      let userFacingError: Error = new Error();
      userFacingError.name = t(`File upload failed`);

      if (!(error instanceof AxiosError)) {
        userFacingError.message = (error as Error).message;
        setError(userFacingError);
        return;
      }

      const response: AxiosResponse<unknown, any> | undefined = error.response;

      if (!response) {
        // User is most likely offline
        const isUserOffline: boolean = error.code === AxiosError.ERR_NETWORK;

        userFacingError.message = isUserOffline
          ? t(
              `It seems like you have no access to the internet right now. Please make sure that your internet connection is stable and then try again.`
            )
          : (error as AxiosError).message;

        setError(userFacingError);
        return;
      }

      const isResponseDataApiException: boolean = isApiException(response.data);

      if (isResponseDataApiException) {
        const apiException: ApiException = response.data as ApiException;

        userFacingError.message = apiException.error.details.reduce(
          (acc, curr) => acc + " " + curr,
          ""
        );
      } else {
        const isAxiosResponseDataString: boolean =
          (response as AxiosResponse<unknown, any>).data instanceof String;

        if (isAxiosResponseDataString) {
          userFacingError.message = (response as AxiosResponse<unknown, any>)
            .data as string;
        } else {
          userFacingError.message = t(
            `Something went wrong. If refreshing the page does not fix the problem then contact support. `
          );
        }
      }

      setError(userFacingError);
    } finally {
      if (isWakeLockActive) await releaseScreenWakeLock();
    }
  };

  return (
    <>
      <Button
        type="button"
        variant="contained"
        fullWidth={isMobileWidth}
        onClick={() => {
          onSendButtonPressed();
        }}
      >
        <div className="font-ibm-plex-sans font-medium">
          {isLoading ? <Spinner color="white" size={16} /> : t(`Send message`)}
        </div>
      </Button>
      {isLoading && (
        <UploadDialogThemeProvider progress={progress}>
          <UploadDialog
            progress={progress}
            draft={transferDraft}
            onDismiss={() => {
              setIsLoading(false);
            }}
          />
        </UploadDialogThemeProvider>
      )}
      {error && (
        <MailboxExceptionDialog
          title={error.name}
          description={error.message}
          onDismiss={() => {
            setIsLoading(false);
            setError(null);
          }}
        />
      )}
    </>
  );
}
