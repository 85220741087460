import { Context, ReactNode, createContext, useContext } from "react";
import { Environment } from "../entities";

type EnvironmentProviderProp = {
  children: ReactNode;
  environment: Environment;
};

const EnvironmentContext: Context<Environment | undefined> = createContext<
  Environment | undefined
>(undefined);

const useEnvironment = (): Environment | undefined =>
  useContext(EnvironmentContext);

/**
 * Provider wrapper which provides `environment` value
 * down the component tree.
 *
 * @param environmentProviderProp
 * @returns an skp-light environment provider wrapper component.
 */
const EnvironmentProvider = (
  environmentProviderProp: EnvironmentProviderProp
): JSX.Element => {
  return (
    <EnvironmentContext.Provider value={environmentProviderProp.environment}>
      {environmentProviderProp.children}
    </EnvironmentContext.Provider>
  );
};

type conditionalEnvironmentProviderProp = {
  condition: boolean;
  environment: Environment | undefined;
  children: ReactNode;
};

const ConditionalEnvironmentProvider = ({
  condition,
  environment,
  children,
}: conditionalEnvironmentProviderProp): JSX.Element => {
  return condition ? (
    <EnvironmentProvider environment={environment!}>
      {children}
    </EnvironmentProvider>
  ) : (
    <>{children}</>
  );
};

export { useEnvironment, EnvironmentProvider, ConditionalEnvironmentProvider };
