import { styled, useThemeProps } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { forwardRef } from "react";
import { cssVar } from "../../../utils/cssVar";

interface ProtectionLockProps {
  size: number;
}

interface ProtectionOwnerState extends ProtectionLockProps {
  // …key value pairs for the internal state that we want to style the slot
  // but don't want to expose them.
}

const ProtectionSolidLockStyled = styled(LockIcon, {
  name: "ProtectionSolidLock",
  slot: "root",
})<{ ownerState: ProtectionOwnerState }>(({ theme, ownerState }) => ({
  fontSize: ownerState.size,
  color: cssVar("--primary-color"),
}));

const ProtectionSolidLock = forwardRef<SVGSVGElement, ProtectionLockProps>(
  function ProtectionSolidLock1(inProps, ref) {
    const props = useThemeProps({
      props: inProps,
      name: "ProtectionSolidLock",
    });
    const { size, ...other } = props;

    const ownerState = { ...props };

    return (
      <ProtectionSolidLockStyled ref={ref} ownerState={ownerState} {...other} />
    );
  }
);

const ProtectionLockStyled = styled(LockOutlinedIcon, {
  name: "ProtectionLock",
  slot: "root",
})<{ ownerState: ProtectionOwnerState }>(({ theme, ownerState }) => ({
  fontSize: ownerState.size,
  color: "#747474",
}));

const ProtectionLock = forwardRef<SVGSVGElement, ProtectionLockProps>(
  function ProtectionLock1(inProps, ref) {
    const props = useThemeProps({
      props: inProps,
      name: "ProtectionLock",
    });
    const { size, ...other } = props;

    const ownerState = { ...props };

    return (
      <ProtectionLockStyled ref={ref} ownerState={ownerState} {...other} />
    );
  }
);

export { ProtectionSolidLock, ProtectionLock };
export type { ProtectionLockProps };
