import { ReactNode, createContext, useContext } from "react";
import { DropzoneState } from "react-dropzone";

type DropzoneContextProviderProp = {
  innerDropzoneState: DropzoneState;
  children: ReactNode;
};

const InnerDropzoneStateContext = createContext<DropzoneState | undefined>(
  undefined
);

const useInnerDropzoneState = () => useContext(InnerDropzoneStateContext);

const DropzoneContextProvider = (
  dropzoneContextProviderProp: DropzoneContextProviderProp
) => {
  return (
    <InnerDropzoneStateContext.Provider
      value={dropzoneContextProviderProp.innerDropzoneState}
    >
      {dropzoneContextProviderProp.children}
    </InnerDropzoneStateContext.Provider>
  );
};

export { useInnerDropzoneState, DropzoneContextProvider };
