import React from "react";
import { Outlet } from "react-router-dom";

/// This component is wrapped around most pages by the router and can be used
/// to display global components or define global suspense/error boundaries etc.
const Root: React.FC<{}> = () => {
  return <Outlet />;
};

export default Root;
