import Button from "@mui/material/Button";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { MouseEventHandler, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  fetchEnvironment,
  fetchPublicboxDetails,
  resolveVanityLink,
} from "../../api";
import Avatar from "../../components/avatar";
import {
  ApiException,
  Environment,
  ProtectionType,
  PublicboxDetails,
  TransferDraft,
  TransferDraftErrors,
} from "../../entities";
import {
  EnvironmentProvider,
  useEnvironment,
} from "../../providers/environment";
import {
  TransferDraftAndOuterDropzoneProvider,
  useOuterDropzoneState,
  useTransferDraft,
  useTransferDraftDispatch,
} from "../../providers/transferDraft";
import { TransferDraftActionType } from "../../reducers/transferDraft";
import { getHumanReadableFileSize } from "../../utils/units";
import ErrorPage from "../error";
import NotFoundPage from "../NotFound";
import MailboxPageSkeleton from "../skeletons/Mailbox";
import PasswordTextFields from "./components/textFields/passwordTextFields";
import SendButton from "./components/send";
import EmailTextField from "./components/textFields/emailTextField";
import NameTextField from "./components/textFields/nameTextField";
import SubjectTextField from "./components/textFields/subjectTextField";
import MessageTextField from "./components/textFields/message";
import { Spacer } from "../../components/layout";
import OptionsButton from "./components/options/optionsBtn";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import {
  AddedFilesChips,
  DropAndFileChipsZone,
} from "./components/dropAndFileChipsZone";
import LoadingScreen from "../loading";

type ContentProps = {
  publicboxDetails: PublicboxDetails;
};

type UsernameProp = {
  username: string;
};

type AddFilesButtonProp = {
  onClick: MouseEventHandler<HTMLButtonElement> | undefined;
};

export default function Mailbox() {
  const { vanityName, publicboxIdx, publicboxKey } = useParams();

  // this constraint is enforced by the router
  if (
    vanityName === undefined &&
    publicboxKey === undefined &&
    publicboxIdx === undefined
  ) {
    throw new Error(
      "Either vanityName or (publicboxKey and publicboxIdx) need to be defined"
    );
  }

  const { status, data, error } = useQuery({
    queryKey: [
      "publicboxDetailsAndEnvironment",
      publicboxKey,
      publicboxIdx,
      vanityName,
    ],
    refetchOnWindowFocus: false,
    retry: false,
    queryFn: async () => {
      return Promise.all([
        !publicboxIdx || !publicboxKey
          ? resolveVanityLink(vanityName!).then(({ key, publicuserIdx }) =>
              fetchPublicboxDetails(String(key), String(publicuserIdx))
            )
          : fetchPublicboxDetails(publicboxKey, publicboxIdx),
        fetchEnvironment(),
      ]);
    },
  });

  switch (status) {
    case "loading":
      return <LoadingScreen />;
    case "error":
      if (error instanceof AxiosError && error.response) {
        if (error.response?.status === 404) {
          return <NotFoundPage />;
        }

        const apiException: ApiException = error.response.data;
        const errorMessage: string = apiException.error.details.reduce(
          (acc, curr) => acc + " " + curr,
          ""
        );
        return <ErrorPage errorMessage={errorMessage} />;
      }

      return <ErrorPage errorMessage={(error as Error).message} />;
    case "success":
      const [publicboxDetails, environment] = data;
      return (
        <EnvironmentProvider environment={environment}>
          <TransferDraftAndOuterDropzoneProvider>
            <div className={`animate-fade-in`}>
              <MailboxPageSkeleton
                childrenVerticalAlignment="start"
                hasMailboxLoaded={true}
              >
                <Content publicboxDetails={publicboxDetails} />
              </MailboxPageSkeleton>
            </div>
          </TransferDraftAndOuterDropzoneProvider>
        </EnvironmentProvider>
      );
  }
}

const Username = ({ username }: UsernameProp) => {
  return (
    <div className="bsm:py-7 truncate text-[16px] font-medium sm:text-2xl">
      {username}
    </div>
  );
};

const AddFilesButton = ({ onClick }: AddFilesButtonProp) => {
  const { t } = useTranslation();
  return (
    <Button variant="outlined" onClick={onClick}>
      {t(`Add file(s)`)}
    </Button>
  );
};

const AddedFilesCountAndSize = () => {
  const { t } = useTranslation();
  const transferDraft: TransferDraft = useTransferDraft();
  const cumulativeAddedFilesSize: number = transferDraft.files.reduce(
    (accumulator, currentFile) => accumulator + currentFile[0].size,
    0
  );
  const addedFilesCount: number = transferDraft.files.length;

  return (
    <div className="text-[11px] text-[#747474]">
      {addedFilesCount}
      {addedFilesCount > 1 ? t(` files`) : t(` file`)} (
      {getHumanReadableFileSize(cumulativeAddedFilesSize)})
    </div>
  );
};

function Content(contentProps: ContentProps) {
  const { t } = useTranslation();
  const outerDropzoneState = useOuterDropzoneState();
  const transferDraft = useTransferDraft();
  const transferDraftDispatch = useTransferDraftDispatch();
  const environment: Environment = useEnvironment()!;
  const { isMobileWidth } = useWindowDimensions();

  const areFilesAdded: boolean = transferDraft.files.length > 0;

  const username: string = contentProps.publicboxDetails.myPublicOwner.realname;

  const isFileCountInvalid: boolean = transferDraft.errors.has(
    TransferDraftErrors.invalidFileCount
  );

  const attachFileTextColor: string = isFileCountInvalid
    ? `var(--warning-color)`
    : "black";

  let showPasswordFields: boolean =
    transferDraft.protectionType ===
    (Object.values(ProtectionType)[1] as ProtectionType);

  useEffect(() => {
    transferDraftDispatch({
      type: TransferDraftActionType.initialize,
      publicboxDetails: contentProps.publicboxDetails,
      environment: environment,
    });
  }, [contentProps.publicboxDetails, environment, transferDraftDispatch]);

  return (
    <div className="flex flex-col items-center">
      <div className="container max-w-screen-lg md:w-3/4">
        <div className="mb-7 flex flex-row items-start sm:m-0 sm:items-center">
          <Avatar
            uid={contentProps.publicboxDetails.myPublicOwner.uid}
            name={contentProps.publicboxDetails.myPublicOwner.realname}
            size={55}
            isAnonymous={false}
          />
          <div className="w-5" />
          <div className="flex flex-col">
            <Username username={username} />
            <div className="text-xs sm:hidden">
              {t("Send me a message to my secure mailbox!")}
            </div>
          </div>
          <Spacer />
          <div className="sm:hidden">
            <OptionsButton />
          </div>
        </div>
        <div className="flex flex-col gap-y-5">
          <div className="hidden sm:block">
            <div className="flex flex-row items-center justify-between pt-3 text-xl">
              {t("Send me a message to my secure mailbox!")}
              <OptionsButton />
            </div>
          </div>
          <div className="flex flex-col gap-y-5 sm:flex-row sm:gap-x-5">
            <NameTextField />
            <EmailTextField />
          </div>
          {showPasswordFields ? <PasswordTextFields /> : <></>}
          <SubjectTextField />
          <MessageTextField />
          <p
            className="text-base"
            style={{
              color: attachFileTextColor,
            }}
          >
            {transferDraft.files!.length > 0
              ? t(`Attached file(s):`)
              : t(`Please add an attachment`)}
          </p>

          {isMobileWidth ? (
            areFilesAdded ? (
              <AddedFilesChips />
            ) : (
              <></>
            )
          ) : (
            <DropAndFileChipsZone />
          )}
          <>
            {isMobileWidth || areFilesAdded ? (
              <div className="flex items-center justify-between">
                <AddFilesButton onClick={outerDropzoneState.open} />
                <AddedFilesCountAndSize />
              </div>
            ) : (
              <></>
            )}
          </>
          <div className="inline-flex"></div>
          <div className="flex flex-row items-center justify-between">
            <p>
              {transferDraft.ttl === 0
                ? t(
                    `Your message will be available for download by the recipient for one day`
                  )
                : t(
                    `Your message will be available for download by the recipient for {{validity}} days`,
                    { validity: `${transferDraft.ttl}` }
                  )}
            </p>
            {!isMobileWidth && (
              <SendButton publicboxDetails={contentProps.publicboxDetails} />
            )}
          </div>
          {isMobileWidth && (
            <div className="sticky bottom-0 bg-white/[0.7] pb-11 pt-6 backdrop-blur-[3px] ">
              <SendButton publicboxDetails={contentProps.publicboxDetails} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
