import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowDownToLine, faFile } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fab from "@mui/material/Fab/Fab";
import IconButton from "@mui/material/IconButton/IconButton";
import { i18n } from "i18next";
import { MutableRefObject, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDownloadUrl, getLegacyBaseURL, getPreviewUrl } from "../../api";
import Avatar from "../../components/avatar";
import { MalwareStatus, Transfer } from "../../entities";
import { cssVar } from "../../utils/cssVar";
import {
  getHumanReadableFileSize,
  padIfSingleDigit,
  padTransferExpirationMonth,
} from "../../utils/units";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import MailboxExceptionDialog from "../mailbox/components/errorDialog";
import PreviewPage from "../preview/Preview";

// TODO refactor in a separate MR
// maybe a transfer provider is a good idea

type SuccessPageProp = {
  transfer: Transfer;
};

type CardProp = SuccessPageProp;

type UserDetailsProp = {
  name?: string;
  email?: string;
};

type ValidityProp = {
  expirationTime?: Date;
};

type AllFilesDetailsProp = {
  totalFilesCount?: number;
  totalFilesSize?: number;
  isTransferInfected: boolean;
};

type FileRowProp = {
  fileName?: string;
  fileSize?: number;
  fileIndex?: number;
  transferRecipientId?: string;
  url?: URL;
  mimeType?: string;
  isFileInfected: boolean;
  malwareText?: string;
};

type FileDetailsProp = {
  fileName?: string;
  fileSize?: number;
  isFileInfected: boolean;
};

type SenderDetailsProp = {
  senderEmail?: string;
  senderName?: string;
  senderUid?: string;
};

type CardHeaderProp = SenderDetailsProp & {
  // Sender sent transfer via skp-light instead of
  // skp-client.
  //
  // At the moment, we have no way of figuring
  // out if the name & email entered by sender in skp-light's form
  // are legitmate or not. Therefore, sender who sends transfer
  // via skp-light are catagorised as anonymous sender.
  isSenderAnonymous: boolean;
  expirationTime?: Date;
  totalFileSize?: number;
  totalFileCount?: number;
  isTransferInfected: boolean;
};

type DownloadAllButtonProp = {
  transferRecipientId?: string;
  isTransferInfected: boolean;
};

type PreviewButtonProp = {
  mimeType?: string;
  transferRecipientId?: string;
  fileIndex?: number;
  fileName?: string;
  onClick: (data: URL) => void;
  buttonColor: string;
};

type PreviewIconProp = {
  color: string;
  height: number;
  width: number;
};

type CalendarIconProp = PreviewIconProp;

type DownloadAllButtonIconProp = PreviewIconProp;

/**
 * Returns content for transfer page.
 *
 * @param contentProp
 * @returns JSX element represeting content.
 */
export default function SuccessPage(contentProp: SuccessPageProp): JSX.Element {
  return (
    <>
      <Card transfer={contentProp.transfer} />
      <DownloadAllButton
        transferRecipientId={contentProp.transfer.recipientId}
        isTransferInfected={
          contentProp.transfer.malwareStatus === Object.values(MalwareStatus)[3]
        }
      />
    </>
  );
}

/**
 * Represents a horizontal line.
 * @returns a JSX element representing a horizontal line.
 */
function Divider() {
  return <hr className="h-[1px] bg-[#E5E5E6]"></hr>;
}

/**
 * Contains the main content of the page.
 * @returns a JSX element representing card.
 */
function Card(cardProp: CardProp) {
  const isTransferInfected =
    cardProp.transfer.malwareStatus === Object.values(MalwareStatus)[3];
  return (
    <div className="mb-5 w-screen max-w-[760px] pb-16 sm:my-20 sm:rounded-xl sm:pb-24 sm:shadow-lg">
      <CardHeader
        isSenderAnonymous={!!cardProp.transfer.anon}
        expirationTime={cardProp.transfer.expirationTimestamp}
        senderEmail={
          cardProp.transfer.anon?.email ??
          cardProp.transfer.sender?.email ??
          "--"
        }
        senderName={
          cardProp.transfer.anon?.realname ??
          cardProp.transfer.sender?.realname ??
          "--"
        }
        senderUid={cardProp.transfer.anon?.uid ?? cardProp.transfer.sender?.uid}
        totalFileCount={cardProp.transfer.files?.length ?? 0}
        totalFileSize={cardProp.transfer.totalFileSize}
        isTransferInfected={isTransferInfected}
      />
      <div className="mx-4 sm:mx-16">
        <div className="mb-[30px] mt-7 text-xs font-[550] sm:mt-12">
          {cardProp.transfer?.subject ?? ""}
        </div>
        <p className="mb-[50px] whitespace-pre-wrap break-words">
          {cardProp.transfer?.description ?? ""}
        </p>
        <div className="pb-3">
          {isTransferInfected && <InfectedTransferText />}
          <Divider />
          {cardProp.transfer.files?.map((file) => (
            <>
              <FileRow
                mimeType={file.mimeType}
                url={file.url}
                fileName={file.name}
                fileSize={file.size}
                fileIndex={Number(
                  new URL(file.url).pathname
                    .split("/")
                    .filter((segment) => segment !== "")
                    .pop()
                )}
                transferRecipientId={cardProp.transfer.recipientId}
                key={file.objectId}
                isFileInfected={
                  file.malware.status === Object.values(MalwareStatus)[3]
                }
                malwareText={file.malware.text}
              />
              <Divider />
            </>
          )) ?? <></>}
        </div>
        {cardProp.transfer.malwareStatus === Object.values(MalwareStatus)[0] ? (
          <HealthyTransferText />
        ) : cardProp.transfer.malwareStatus ===
          Object.values(MalwareStatus)[1] ? (
          <UnscannedTransferText />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

type DownloadFileProp = {
  downloadURL: URL;
  wasErrorEncounteredRef: MutableRefObject<boolean>;
  onErrorEncountered: (error: Error | null) => void;
  event: React.MouseEvent<HTMLAnchorElement>;
  i18n: i18n;
};

const downloadFile = (downloadFileProp: DownloadFileProp) => {
  downloadFileProp.event.preventDefault();
  fetch(downloadFileProp.downloadURL, { method: "HEAD" }).then((response) => {
    if (downloadFileProp.wasErrorEncounteredRef.current) {
      downloadFileProp.wasErrorEncounteredRef.current = false;
      return;
    }

    if (response.ok) {
      window.location.href = downloadFileProp.downloadURL.href;
    } else {
      let userFacingError: Error = new Error();
      userFacingError.name = downloadFileProp.i18n.t(`Something went wrong`);
      userFacingError.message = downloadFileProp.i18n.t(
        `The file could not be downloaded. There was a problem accessing the file.`
      );
      downloadFileProp.wasErrorEncounteredRef.current = true;
      downloadFileProp.onErrorEncountered(userFacingError);
    }
  });
};

/**
 * Consists of file details & action buttons for previewing and downloading
 * the file.
 * @returns JSX element representing row.
 */
function FileRow(fileRowProp: FileRowProp) {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState<Error | null>(null);
  const wasErrorEncounteredRef = useRef<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const filePreviewUrlRef = useRef<URL | undefined>(undefined);
  // TODO override style via mui theme provider.
  const buttonColor = fileRowProp.isFileInfected
    ? cssVar("--warning-color")
    : cssVar("--primary-color");
  const fileDetailsBasis = fileRowProp.isFileInfected
    ? `basis-1/3`
    : `basis-full`;
  const infectedFileTextBasis = fileRowProp.isFileInfected
    ? `basis-2/3`
    : `basis-0`;
  const infectedFileText = fileRowProp.malwareText
    ? t(`!! INFECTED WITH {{malware}} !!`, {
        malware: `${fileRowProp.malwareText}`,
      })
    : t(`!! INFECTED WITH UNKNOWN MALWARE !!`);

  const baseURL: string = getLegacyBaseURL();
  const downloadURL: URL = new URL(
    `download/${fileRowProp.transferRecipientId}/${
      fileRowProp.fileIndex
    }/${encodeURIComponent(fileRowProp.fileName!)}`,
    baseURL
  );

  return (
    <div className="flex h-12 flex-row items-center py-[10px]">
      <div className="mr-3">
        <PreviewButton
          fileIndex={fileRowProp.fileIndex}
          fileName={fileRowProp.fileName}
          mimeType={fileRowProp.mimeType}
          transferRecipientId={fileRowProp.transferRecipientId}
          onClick={(filePreviewUrl) => {
            filePreviewUrlRef.current = filePreviewUrl;
            setShowPreview(true);
          }}
          buttonColor={buttonColor}
        />
        {showPreview && (
          <PreviewPage
            fileIndex={fileRowProp.fileIndex}
            fileName={fileRowProp.fileName}
            mimeType={fileRowProp.mimeType}
            filePreviewUrl={filePreviewUrlRef.current}
            transferRecipientId={fileRowProp.transferRecipientId}
            onClose={() => setShowPreview(false)}
          />
        )}
      </div>
      <div className="mr-10">
        <a
          href={downloadURL.href}
          onClick={(event) =>
            downloadFile({
              event: event,
              downloadURL: downloadURL,
              i18n: i18n,
              onErrorEncountered: setError,
              wasErrorEncounteredRef: wasErrorEncounteredRef,
            })
          }
        >
          <IconButton
            sx={{
              height: "40px",
              width: "40px",
            }}
          >
            <FontAwesomeIcon
              icon={faArrowDownToLine as IconProp}
              style={{ color: "var(--primary-color)" }}
              className={`text-[24px]`}
            />
          </IconButton>
        </a>
        {error && (
          <MailboxExceptionDialog
            title={error.name}
            description={`${error.message}`}
            onDismiss={() => setError(null)}
          />
        )}
      </div>

      <div className="flex grow flex-row items-center">
        <div className={`${fileDetailsBasis} overflow-hidden`}>
          <FileDetails
            fileName={fileRowProp.fileName}
            fileSize={fileRowProp.fileSize}
            isFileInfected={fileRowProp.isFileInfected}
          />
        </div>
        {fileRowProp.isFileInfected ? (
          <div
            className={`${infectedFileTextBasis} pl-3 text-[10px] text-[#FC7F02]`}
          >
            {infectedFileText}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

/**
 * Button for downloading all the files of the current transfer.
 * @returns a JSX element representing a Floating action button.
 */
function DownloadAllButton(downloadAllButtonProp: DownloadAllButtonProp) {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState<Error | null>(null);
  const wasErrorEncounteredRef = useRef<boolean>(false);
  const buttonColor = downloadAllButtonProp.isTransferInfected
    ? cssVar("--warning-color")
    : cssVar("--primary-color");
  const baseURL: string = getLegacyBaseURL();
  const downloadURL: URL = new URL(
    `download/${downloadAllButtonProp.transferRecipientId}/zip`,
    baseURL
  );
  return (
    <a
      href={downloadURL.href}
      className="fixed bottom-5 right-4"
      download=""
      onClick={(event) =>
        downloadFile({
          event: event,
          downloadURL: downloadURL,
          i18n: i18n,
          onErrorEncountered: setError,
          wasErrorEncounteredRef: wasErrorEncounteredRef,
        })
      }
    >
      <Fab
        variant="extended"
        sx={{
          backgroundColor: buttonColor,
          color: "#FFFFFF",
          borderRadius: 18,
          ":hover": {
            backgroundColor: buttonColor,
            filter: "brightness(0.95)",
          },
        }}
      >
        <DownloadAllButtonIcon color="#FFFFFF" height={18.767} width={15.796} />
        <div className="ml-2 hidden sm:block">{t(`Download all`)}</div>
      </Fab>
      {error && (
        <MailboxExceptionDialog
          title={error.name}
          description={error.message}
          onDismiss={() => {
            setError(null);
          }}
        />
      )}
    </a>
  );
}

/**
 * Contains file name and it's size.
 * @returns a JSX element representing file details.
 */
function FileDetails(fileDetailsProp: FileDetailsProp) {
  const maxFileDetailsWidth = fileDetailsProp.isFileInfected
    ? `max-w-[190px]`
    : `max-w-[500px]`;
  const fileNameColor = fileDetailsProp.isFileInfected ? "text-[#FC7F02]" : "";
  return (
    <div className={`flex flex-col`}>
      <div
        className={`${maxFileDetailsWidth} truncate ${fileNameColor} text-xs`}
      >
        {fileDetailsProp.fileName ?? ""}
      </div>
      <div className="text-[10px] text-[#747474]">
        {getHumanReadableFileSize(fileDetailsProp.fileSize ?? 0)}
      </div>
    </div>
  );
}

/**
 * Consists of user avatar & its details.
 * @returns a JSX element representing the header of the card.
 */
function CardHeader(cardHeaderProp: CardHeaderProp) {
  const { isMobileWidth } = useWindowDimensions();
  const avatarSize: number = !isMobileWidth ? 60 : 40;

  return (
    <div className="flex h-24 flex-col items-center justify-center bg-[#F7F7F7] px-[30px] sm:h-40 sm:rounded-t-xl sm:py-6">
      <Avatar
        isAnonymous={cardHeaderProp.isSenderAnonymous}
        uid={cardHeaderProp.senderUid}
        name={cardHeaderProp.senderName}
        size={avatarSize}
      />
      <div className="h-0.5 sm:h-2" />
      <div className="flex flex-col">
        <div className="flex items-center">
          <UserDetails
            name={cardHeaderProp.senderName}
            email={cardHeaderProp.senderEmail}
          />
          {cardHeaderProp.isSenderAnonymous && (
            <div className="whitespace-pre text-sm font-medium sm:text-lg">
              {" "}
              via Upload-Link
            </div>
          )}
        </div>
        <div className="flex items-center justify-center gap-x-4">
          <AllFilesDetails
            totalFilesCount={cardHeaderProp.totalFileCount}
            totalFilesSize={cardHeaderProp.totalFileSize}
            isTransferInfected={cardHeaderProp.isTransferInfected}
          />
          <Validity expirationTime={cardHeaderProp.expirationTime} />
        </div>
      </div>
    </div>
  );
}

/**
 *  Consists of user's name & email.
 * @returns a JSX element representing user's details.
 */
function UserDetails(userDetailsProp: UserDetailsProp) {
  return (
    <div className="flex max-w-[700px] text-sm sm:text-lg">
      <div className="truncate font-medium">{userDetailsProp.name}&nbsp;</div>
      <div className="truncate">({userDetailsProp.email})</div>
    </div>
  );
}

/**
 * Consists of total number of files & their size.
 * @returns a JSX element representing details of all files.
 */
function AllFilesDetails(allFilesDetailsProp: AllFilesDetailsProp) {
  const { t } = useTranslation();
  const totalFiles = allFilesDetailsProp?.totalFilesCount ?? 0;
  const filesDetailsColor = allFilesDetailsProp.isTransferInfected
    ? `#C40606`
    : `#747474`;

  return (
    <div className="flex flex-row items-center gap-x-[5px]">
      <FontAwesomeIcon
        icon={faFile as IconProp}
        className={`text-[12px] text-[${filesDetailsColor}]`}
      />
      <div
        className={`flex flex-row gap-x-0.5 text-[10px] text-[${filesDetailsColor}]`}
      >
        <div>
          {totalFiles} {totalFiles > 1 ? t(`Files`) : t(`File`)}
        </div>
        <div>
          ({getHumanReadableFileSize(allFilesDetailsProp?.totalFilesSize ?? 0)})
        </div>
      </div>
    </div>
  );
}

/**
 * Contains the expiry date of the transfer.
 * @returns a JSX element representing the validity of the transfer.
 */
function Validity(validityProp: ValidityProp) {
  const { t } = useTranslation();
  const date = validityProp.expirationTime?.getDate();
  const paddedDate = padIfSingleDigit(date);

  const year = validityProp.expirationTime?.getFullYear();

  const month = validityProp.expirationTime?.getMonth();
  const paddedMonth = padTransferExpirationMonth(month);

  const validity: string =
    paddedDate && paddedMonth && year
      ? `${paddedDate}.${paddedMonth}.${year}`
      : `-`;
  return (
    <div className="flex flex-row items-center gap-x-[5px]">
      <CalendarIcon color="#747474" height={11.5} width={10.5} />
      <div className="flex flex-row gap-x-0.5 text-[10px] text-[#747474]">
        <div>{t(`Valid until`)}</div>
        <div>{validity}</div>
      </div>
    </div>
  );
}

function PreviewButton(previewButtonProp: PreviewButtonProp) {
  return (
    <IconButton
      onClick={async () => {
        const result =
          previewButtonProp.mimeType?.startsWith("video") ||
          previewButtonProp.mimeType?.endsWith("pdf")
            ? getDownloadUrl({
                fileIndex: previewButtonProp.fileIndex,
                transferRecipientId: previewButtonProp.transferRecipientId,
                fileName: previewButtonProp.fileName,
              })
            : getPreviewUrl({
                fileIndex: previewButtonProp.fileIndex,
                transferRecipientId: previewButtonProp.transferRecipientId,
              });
        previewButtonProp.onClick(result);
      }}
    >
      <PreviewIcon
        color={previewButtonProp.buttonColor}
        height={20}
        width={22}
      />
    </IconButton>
  );
}

/**
 * Text stating that the received transfer is infected with malware.
 *
 * @returns a JSX element represeting infected transfer text.
 */
function InfectedTransferText() {
  const { t } = useTranslation();

  return (
    <div className="pb-[7px] text-[10px] text-[#FC7F02]">
      {t(
        `All files have been scanned by the TeamBeam malware scan. One or more files may be infected.`
      )}
    </div>
  );
}

/**
 * Text stating that the received transfer contains no malware.
 *
 * @returns a JSX element represeting infected transfer text.
 */
function HealthyTransferText() {
  const { t } = useTranslation();

  return (
    <div className="text-[10px] text-[#747474]">
      {t(
        `TeamBeam virus scan has checked the attachments. No malware was found.`
      )}
    </div>
  );
}

/**
 * Text stating that the received transfer hasn't been scanned for malware.
 *
 * @returns a JSX element represeting infected transfer text.
 */
function UnscannedTransferText() {
  const { t } = useTranslation();

  return (
    <div className="text-[10px] text-[#747474]">
      {t(`This transfer has not been scanned for malware yet`)}
    </div>
  );
}

function PreviewIcon(previewIconProp: PreviewIconProp) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={previewIconProp.height}
        width={previewIconProp.width}
        viewBox="0 0 576 500"
        fill={previewIconProp.color}
      >
        <path d="M288 144a110.9 110.9 0 0 0 -31.2 5 55.4 55.4 0 0 1 7.2 27 56 56 0 0 1 -56 56 55.4 55.4 0 0 1 -27-7.2A111.7 111.7 0 1 0 288 144zm284.5 97.4C518.3 135.6 410.9 64 288 64S57.7 135.6 3.5 241.4a32.4 32.4 0 0 0 0 29.2C57.7 376.4 165.1 448 288 448s230.3-71.6 284.5-177.4a32.4 32.4 0 0 0 0-29.2zM288 400c-98.7 0-189.1-55-237.9-144C98.9 167 189.3 112 288 112s189.1 55 237.9 144C477.1 345 386.7 400 288 400z" />
      </svg>
    </div>
  );
}

function CalendarIcon(calendarIconProp: CalendarIconProp) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={`${calendarIconProp.height}`}
        width={`${calendarIconProp.width}`}
        fill={calendarIconProp.color}
        viewBox="0 0 448 512"
      >
        <path d="M400 64h-48V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H128V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h352c8.8 0 16 7.2 16 16v48H32v-48c0-8.8 7.2-16 16-16zm352 384H48c-8.8 0-16-7.2-16-16V192h384v272c0 8.8-7.2 16-16 16zM148 320h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 96h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm192 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12z" />
      </svg>
    </div>
  );
}

function DownloadAllButtonIcon(
  downloadAllButtonIconProp: DownloadAllButtonIconProp
) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={downloadAllButtonIconProp.width}
        height={downloadAllButtonIconProp.height}
        fill={downloadAllButtonIconProp.color}
        viewBox="0 0 18.767 15.796"
      >
        <g
          id="Gruppe_1407"
          data-name="Gruppe 1407"
          transform="translate(0.136 -1.734)"
        >
          <path
            id="Pfad_55"
            data-name="Pfad 55"
            d="M1.477-.791H3.516V0H.193V-.542l2.033-2.93H.182v-.794H3.5v.527ZM5.077,0H4.052V-4.266H5.077ZM6.82-1.447V0H5.792V-4.266h1.7a1.983,1.983,0,0,1,.866.18,1.358,1.358,0,0,1,.583.513,1.4,1.4,0,0,1,.207.754,1.234,1.234,0,0,1-.447,1,1.854,1.854,0,0,1-1.226.374Zm0-.794h.674a.635.635,0,0,0,.456-.149.555.555,0,0,0,.157-.422.686.686,0,0,0-.161-.478.571.571,0,0,0-.439-.182H6.82Z"
            transform="translate(9.481 6)"
          />
          <path
            id="arrow-to-bottom"
            d="M11.453,38.576,6.589,43.453a.4.4,0,0,1-.559,0L1.167,38.576a.4.4,0,0,1,0-.559l.644-.644a.39.39,0,0,1,.562.007l3.079,3.191V32.394A.4.4,0,0,1,5.846,32h.92a.4.4,0,0,1,.394.394v8.176L10.24,37.38a.4.4,0,0,1,.562-.007l.644.644A.389.389,0,0,1,11.453,38.576Zm.772,6.438H.394A.4.4,0,0,0,0,45.408v.92a.4.4,0,0,0,.394.394H12.225a.4.4,0,0,0,.394-.394v-.92A.4.4,0,0,0,12.225,45.014Z"
            transform="translate(-0.136 -29.192)"
          />
        </g>
      </svg>
    </div>
  );
}
