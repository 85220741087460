import { AxiosResponse } from "axios";
import isURL from "./urlValidator";

/**
 * Transforms timestamp fields from string type to Date type, if the string
 * has timestamp, & URL type , if the string is a url.
 * and then returns the modified response.
 *
 * @param response network call response.
 * @returns response with timestamp fields converted to Date type.
 */
function transformToDateAndUrlType(
  response: AxiosResponse<any, any>
): AxiosResponse<any, any> {
  if (!response) return response;
  // Regex for ISO format date.
  const regexISO =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

  // Function to transform date fields of type `string` to type `Date`.
  const dateAndUrlParser = function (key: string, value: any) {
    const isSubjectOrMessage: boolean =
      key === "description" || key === "subject";

    if (typeof value !== "string" || isSubjectOrMessage) return value;

    return regexISO.test(value)
      ? new Date(value)
      : isURL(value)
      ? new URL(value)
      : value;
  };

  const updatedData = JSON.parse(
    JSON.stringify(response.data),
    dateAndUrlParser
  );

  response.data = updatedData;

  return response;
}

export { transformToDateAndUrlType };
