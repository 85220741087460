import { useEffect, useState } from "react";

type WindowDimensionsResult = {
  windowDimensions: WindowDimensions;
  isMobileWidth: boolean;
};

type WindowDimensions = {
  width: number;
  height: number;
};

const getWindowDimensions = (): WindowDimensions => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

const useWindowDimensions = (): WindowDimensionsResult => {
  const initialWindowDimensions: WindowDimensions = getWindowDimensions();
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(
    initialWindowDimensions
  );

  const isMobileWidth: boolean = windowDimensions.width <= 640;

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowDimensions(getWindowDimensions);
    });

    return window.removeEventListener("resize", () => {
      setWindowDimensions(getWindowDimensions);
    });
  }, []);

  return { windowDimensions, isMobileWidth };
};

export { useWindowDimensions };

export type { WindowDimensions, WindowDimensionsResult };
