import { ReactNode } from "react";
import TopBanner from "../../components/topBanner";
import { Footer } from "../../components/footer";
import { Spacer } from "../../components/layout";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

type TransferPageSkeletonProp = {
  children: ReactNode;
  childrenVerticalAlignment: string;
  hasMailboxLoaded: boolean;
};

/**
 * Layout skeleton for TransferPage.
 *
 * @param {TransferPageSkeletonProp} transferPageSkeletonProp
 *
 * @returns a wrapper JSX element.
 */
export default function TransferPageSkeleton(
  transferPageSkeletonProp: TransferPageSkeletonProp
) {
  const { isMobileWidth } = useWindowDimensions();
  const shouldChildrenBeCentered: boolean =
    transferPageSkeletonProp.childrenVerticalAlignment === "center";
  const childrenClassName: string = shouldChildrenBeCentered
    ? "flex grow items-center justify-center"
    : "";

  return (
    <div className="flex min-h-screen w-full flex-col">
      <TopBanner />
      <div className={childrenClassName}>
        {transferPageSkeletonProp.children}
      </div>
      {isMobileWidth && !shouldChildrenBeCentered ? <Spacer /> : <></>}
      {transferPageSkeletonProp.hasMailboxLoaded && <Footer />}
    </div>
  );
}
