import { ClickAwayListener, Drawer } from "@mui/material";
import OptionsPage from "./optionsPage";

type SideDrawerProp = {
  onDismiss: () => void;
  isOpen: boolean;
};

// Todo dismiss via esc key doesnt work
/**
 * Drawer which slides from the right side of the broswer window.
 * It contains some of the {@link TransferDraft}'s configurations.
 *
 * @returns JSX element representing {@link Drawer}.
 */
export default function SideDrawer({ onDismiss, isOpen }: SideDrawerProp) {
  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={(event) => (isOpen ? onDismiss() : null)}
    >
      <Drawer
        variant="persistent"
        ModalProps={{
          keepMounted: true,
          disableScrollLock: false,
        }}
        PaperProps={{
          elevation: 6,
        }}
        hideBackdrop={true}
        anchor={"right"}
        open={isOpen}
        onClose={(property, reason) => onDismiss()}
      >
        <OptionsPage onDismiss={onDismiss} />
      </Drawer>
    </ClickAwayListener>
  );
}