import {
  Environment,
  ProtectionType,
  PublicboxDetails,
  TransferDraft,
  TransferDraftErrors,
  TransferDraftFile,
  TransferReceiver,
} from "../entities";
import { assertNever } from "../utils/typeGuard";

type InitializationAction = {
  type: TransferDraftActionType.initialize;
  publicboxDetails: PublicboxDetails;
  environment: Environment;
};

type ResetAction = {
  type: TransferDraftActionType.reset;
  environment: Environment;
};

type ValidityUpdateAction = {
  type: TransferDraftActionType.validityExtension;
  updatedValidity: number;
};

type FilesAddAction = {
  type: TransferDraftActionType.filesAdd;
  addedFiles: File[];
};

type FilesRemoveAction = {
  type: TransferDraftActionType.filesRemove;
  removedFiles: TransferDraftFile[];
};

type SenderNameUpdateAction = {
  type: TransferDraftActionType.senderNameUpdate;
  updatedName: string;
};

type SenderEmailUpdateAction = {
  type: TransferDraftActionType.senderEmailUpdate;
  updatedEmail: string;
};

type SubjectUpdateAction = {
  type: TransferDraftActionType.subjectUpdate;
  updatedSubject: string;
};

type DescriptionUpdateAction = {
  type: TransferDraftActionType.descriptionUpdate;
  updatedDescription: string;
};

type ErrorUpdateAction = {
  type: TransferDraftActionType.errorUpdate;
  postValidationErrors: Set<TransferDraftErrors>;
};

type SenderEmailValidationAction = {
  type: TransferDraftActionType.senderEmailValidate;
  inputtedSenderEmail: string;
};

type AcknowledgementUpdateAction = {
  type: TransferDraftActionType.acknowledgementUpdate;
  isSelected: boolean;
};

type TtlUpdateAction = {
  type: TransferDraftActionType.ttlUpdate;
  updatedTtl: number;
};

type PriorityUpdateAction = {
  type: TransferDraftActionType.priorityUpdate;
  updatedPriority: number;
};

type ProtectionTypeUpdateAction = {
  type: TransferDraftActionType.protectionTypeUpdate;
  updatedProtectionType: ProtectionType;
};

type PasswordValidationAction = {
  type: TransferDraftActionType.passwordValidate;
  inputtedPassword: string;
  environment: Environment;
};

type RepeatPasswordValidationAction = {
  type: TransferDraftActionType.repeatPasswordValidate;
  inputtedRepeatPassword: string;
};

type PasswordUpdateAction = {
  type: TransferDraftActionType.passwordUpdate;
  updatedPassword: string;
};

type RepeatPasswordUpdateAction = {
  type: TransferDraftActionType.repeatPasswordUpdate;
  updatedRepeatPassword: string;
};

type SenderNameValidateAction = {
  type: TransferDraftActionType.senderNameValidate;
  inputtedSenderName: string;
};

type TransferDraftAction =
  | InitializationAction
  | ResetAction
  | ValidityUpdateAction
  | FilesAddAction
  | FilesRemoveAction
  | SenderNameValidateAction
  | SenderNameUpdateAction
  | SenderEmailUpdateAction
  | SubjectUpdateAction
  | DescriptionUpdateAction
  | ErrorUpdateAction
  | SenderEmailValidationAction
  | AcknowledgementUpdateAction
  | TtlUpdateAction
  | PriorityUpdateAction
  | ProtectionTypeUpdateAction
  | PasswordValidationAction
  | RepeatPasswordValidationAction
  | PasswordUpdateAction
  | RepeatPasswordUpdateAction
  | ProtectionTypeUpdateAction;

enum TransferDraftActionType {
  initialize,
  reset,
  validityExtension,
  filesAdd,
  filesRemove,
  senderNameUpdate,
  senderEmailUpdate,
  subjectUpdate,
  descriptionUpdate,
  senderNameValidate,
  senderEmailValidate,
  errorUpdate,
  acknowledgementUpdate,
  ttlUpdate,
  priorityUpdate,
  protectionTypeUpdate,
  passwordValidate,
  repeatPasswordValidate,
  passwordUpdate,
  repeatPasswordUpdate,
}

function transferDraftReducer(
  transferDraft: TransferDraft,
  action: TransferDraftAction
): TransferDraft {
  switch (action.type) {
    case TransferDraftActionType.initialize:
      const publicboxDetails: PublicboxDetails = action.publicboxDetails;
      const environment: Environment = action.environment;

      const owner: TransferReceiver = {
        realname: publicboxDetails.myPublicOwner.realname,
        email: publicboxDetails.myPublicOwner.email,
        type: "to",
      };

      return {
        ...transferDraft,
        anon: {
          name: "",
          email: "",
        },
        subject: "",
        description: "",
        receivers: [owner],
        errors: new Set<TransferDraftErrors>(),
        acknowledgement: true,
        ttl: environment.expiration.default,
        priority: 3,
        protectionType: getProtectionType(environment),
      };
    // eslint-disable-next-line no-duplicate-case
    case TransferDraftActionType.reset:
      return {
        ...transferDraft,
        subject: "",
        description: "",
        files: [],
        acknowledgement: true,
        ttl: action.environment.expiration.default,
        // The default priority is always normal unless the sender changes it.
        priority: 3,
        protectionType: getProtectionType(action.environment),
        anon: {
          name: "",
          email: "",
        },
        errors: new Set<TransferDraftErrors>(),
        password: undefined,
        repeatPassword: undefined,
      };
    case TransferDraftActionType.validityExtension:
      return {
        ...transferDraft,
        ttl: action.updatedValidity,
      };
    case TransferDraftActionType.filesAdd:
      transferDraft.errors.delete(TransferDraftErrors.invalidFileCount);
      return {
        ...transferDraft,
        files: [
          ...transferDraft.files,
          ...action.addedFiles.map(createTransferDraftFile),
        ],
      };
    case TransferDraftActionType.filesRemove:
      var filesToBeRemoved = action.removedFiles;
      while (filesToBeRemoved.length !== 0) {
        const fileToBeRemoved = filesToBeRemoved.pop()!;
        transferDraft.files = transferDraft.files.filter(
          (file) => file[0].id !== fileToBeRemoved[0].id
        );
      }
      return {
        ...transferDraft,
      };
    case TransferDraftActionType.senderNameUpdate:
      return {
        ...transferDraft,
        anon: {
          ...transferDraft.anon,
          name: action.updatedName,
        },
      };
    case TransferDraftActionType.senderEmailUpdate:
      return {
        ...transferDraft,
        anon: {
          ...transferDraft.anon,
          email: action.updatedEmail,
        },
      };
    case TransferDraftActionType.subjectUpdate:
      return {
        ...transferDraft,
        subject: action.updatedSubject,
      };
    case TransferDraftActionType.descriptionUpdate:
      return {
        ...transferDraft,
        description: action.updatedDescription,
      };
    case TransferDraftActionType.senderNameValidate:
      const trimmedSenderName = transferDraft.anon.name.trim();

      if (!trimmedSenderName) {
        transferDraft.errors.add(TransferDraftErrors.blankSenderName);
      } else {
        transferDraft.errors.delete(TransferDraftErrors.blankSenderName);
      }

      return { ...transferDraft };
    case TransferDraftActionType.senderEmailValidate:
      const trimmedSenderEmail = transferDraft.anon.email.trim();
      const senderEmailMatch = trimmedSenderEmail.match(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      );
      if (senderEmailMatch === null) {
        transferDraft.errors.add(TransferDraftErrors.invalidSenderEmail);
      } else {
        transferDraft.errors.delete(TransferDraftErrors.invalidSenderEmail);
      }
      return { ...transferDraft };

    case TransferDraftActionType.errorUpdate:
      action.postValidationErrors.forEach((error) => {
        transferDraft.errors.add(error);
      });

      return {
        ...transferDraft,
      };
    case TransferDraftActionType.acknowledgementUpdate:
      return {
        ...transferDraft,
        acknowledgement: action.isSelected,
      };
    case TransferDraftActionType.ttlUpdate:
      return {
        ...transferDraft,
        ttl: action.updatedTtl,
      };
    case TransferDraftActionType.priorityUpdate:
      return {
        ...transferDraft,
        priority: action.updatedPriority,
      };
    case TransferDraftActionType.protectionTypeUpdate:
      return {
        ...transferDraft,
        protectionType: action.updatedProtectionType,
      };
    case TransferDraftActionType.passwordValidate:
      const inputtedPassword: string = action.inputtedPassword;

      if (!inputtedPassword) {
        transferDraft.errors.add(TransferDraftErrors.blankPassword);

        return {
          ...transferDraft,
        };
      } else {
        transferDraft.errors.delete(TransferDraftErrors.blankPassword);
      }

      let hasMinLength: boolean =
        inputtedPassword.length >= action.environment.minimumPasswordLength;

      let obeysExtendedPasswordRequirements: boolean = true;

      if (action.environment.extendedPasswordCriteriaEnabled) {
        let hasUpperCase: boolean = /[A-Z]/.test(action.inputtedPassword);

        let hasLowerCase: boolean = /[a-z]/.test(action.inputtedPassword);
        let hasNumeral: boolean = /\d/.test(action.inputtedPassword);
        let hasSpecialChar: boolean = /[!@#$%^&*(),.?":{}|<>]/.test(
          action.inputtedPassword
        );
        obeysExtendedPasswordRequirements =
          3 <=
          (hasUpperCase ? 1 : 0) +
            (hasLowerCase ? 1 : 0) +
            (hasNumeral ? 1 : 0) +
            (hasSpecialChar ? 1 : 0);
      }

      if (!hasMinLength) {
        transferDraft.errors.add(
          TransferDraftErrors.wrongMinimumPasswordLength
        );
      } else {
        transferDraft.errors.delete(
          TransferDraftErrors.wrongMinimumPasswordLength
        );
      }

      if (!obeysExtendedPasswordRequirements) {
        transferDraft.errors.add(
          TransferDraftErrors.disobeysExtendedPasswordRequirements
        );
      } else {
        transferDraft.errors.delete(
          TransferDraftErrors.disobeysExtendedPasswordRequirements
        );
      }

      return { ...transferDraft };
    case TransferDraftActionType.repeatPasswordValidate:
      let inputtedRepeatPassword: string = action.inputtedRepeatPassword;

      if (!inputtedRepeatPassword) {
        transferDraft.errors.add(TransferDraftErrors.blankRepeatPassword);

        return { ...transferDraft };
      } else {
        transferDraft.errors.delete(TransferDraftErrors.blankRepeatPassword);
      }

      if (inputtedRepeatPassword !== transferDraft.password) {
        transferDraft.errors.add(TransferDraftErrors.invalidRepeatPassword);
      } else {
        transferDraft.errors.delete(TransferDraftErrors.invalidRepeatPassword);
      }

      return { ...transferDraft };
    case TransferDraftActionType.passwordUpdate:
      return {
        ...transferDraft,
        password: action.updatedPassword,
      };
    case TransferDraftActionType.repeatPasswordUpdate:
      return {
        ...transferDraft,
        repeatPassword: action.updatedRepeatPassword,
      };
    default:
      assertNever(action);
  }
}

function createTransferDraftFile(file: File): TransferDraftFile {
  return [
    {
      id: crypto.randomUUID(),
      name: file.name,
      size: file.size,
    },
    file,
  ];
}

const getProtectionType = (environment: Environment): ProtectionType => {
  let protectionType: ProtectionType;

  if (environment.transferPasswordRequired) {
    protectionType = Object.values(ProtectionType)[1] as ProtectionType;
  } else if (environment.recipientAuthenticationEnabled) {
    protectionType = Object.values(ProtectionType)[2] as ProtectionType;
  } else {
    protectionType = Object.values(ProtectionType)[0] as ProtectionType;
  }

  return protectionType;
};

export { TransferDraftActionType, transferDraftReducer };
export type { TransferDraftAction };
