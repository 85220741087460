import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormControl from "@mui/material/FormControl/FormControl";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select/Select";
import { useTranslation } from "react-i18next";

export default function LocaleSwitcher() {
  const { i18n } = useTranslation();
  // all available locales on the i18n instance
  const availableLanguageCodes: string[] = [
    ...Object.keys(i18n.services.resourceStore.data),
    // default fallback language for which there is no translation file
    "en",
  ];

  return (
    <FormControl>
      <Select
        MenuProps={{
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          elevation: 3,
          PaperProps: {
            sx: {
              borderRadius: 0,
            },
          },
        }}
        renderValue={(value) => {
          const languageNameInResolvedLanguage: Intl.DisplayNames =
            new Intl.DisplayNames(value as Intl.LocalesArgument, {
              type: "language",
            });

          const parsedLocale = new Intl.Locale(value);

          return (
            <div className="flex items-center">
              <div className="flex-shrink">
                <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                  {languageNameInResolvedLanguage.of(parsedLocale.language)}
                </span>
              </div>
              <FontAwesomeIcon
                icon={solid("earth-europe")}
                className="h-4 w-4 flex-shrink-0 pl-2.5"
              />
            </div>
          );
        }}
        IconComponent={(props) => (
          <FontAwesomeIcon icon={faChevronDown as IconProp} {...props} />
        )}
        autoWidth
        notched={false}
        input={<OutlinedInput label="Tag" />}
        // resolvedLanguage will be undefined if the default fallback language (english) is used
        value={i18n.resolvedLanguage || i18n.language}
        onChange={async function (event, child) {
          await i18n.changeLanguage(event.target.value as string);
        }}
      >
        {availableLanguageCodes.map((languageCode) => {
          const languageDisplayNames: Intl.DisplayNames = new Intl.DisplayNames(
            languageCode,
            {
              type: "language",
            }
          );
          return (
            <MenuItem
              value={languageCode}
              key={languageCode}
              color="#747474"
              sx={{
                width: "140px",
                fontSize: "14px",
                paddingLeft: "22px",
                color: "#747474",
              }}
            >
              {languageDisplayNames.of(languageCode)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
