import TextField from "@mui/material/TextField/TextField";
import {
  useTransferDraft,
  useTransferDraftDispatch,
} from "../../../../providers/transferDraft";
import { TransferDraftErrors } from "../../../../entities";
import { TransferDraftActionType } from "../../../../reducers/transferDraft";
import { useTranslation } from "react-i18next";

export default function NameTextField() {
  const transferDraft = useTransferDraft();
  const transferDraftDispatch = useTransferDraftDispatch();
  const { t } = useTranslation();

  return (
    <TextField
      error={transferDraft.errors.has(TransferDraftErrors.blankSenderName)}
      helperText={
        transferDraft.errors.has(TransferDraftErrors.blankSenderName)
          ? t(`Please add a name in order to continue`)
          : undefined
      }
      value={transferDraft.anon.name}
      variant="filled"
      required
      fullWidth
      type="text"
      autoFocus={true}
      label={t(`Your name`)}
      InputProps={{
        disableUnderline: true,
      }}
      onBlur={(prop) => {
        transferDraftDispatch({
          type: TransferDraftActionType.senderNameValidate,
          inputtedSenderName: prop.target.value,
        });
      }}
      // TODO this is not triggered when auto-filling
      onChange={(newValue) => {
        transferDraftDispatch({
          type: TransferDraftActionType.senderNameUpdate,
          updatedName: newValue.target.value,
        });
      }}
    />
  );
}
