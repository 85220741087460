import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import TextField from "@mui/material/TextField/TextField";
import { i18n } from "i18next";
import { useTranslation } from "react-i18next";
import ts from "typescript";
import { Environment, TransferDraftErrors } from "../../../../entities";
import { useEnvironment } from "../../../../providers/environment";
import {
  useTransferDraft,
  useTransferDraftDispatch,
} from "../../../../providers/transferDraft";
import { TransferDraftActionType } from "../../../../reducers/transferDraft";
import PasswordInfoPopOver from "../passwordInfoPopOver";

export default function PasswordTextFields() {
  const { t, i18n } = useTranslation();
  const transferDraft = useTransferDraft();
  const transferDraftDispatch = useTransferDraftDispatch();
  const environment = useEnvironment()!;

  const isPswdFieldBlank: boolean = transferDraft.errors.has(
    TransferDraftErrors.blankPassword
  );
  const isPswdTooShort: boolean = transferDraft.errors.has(
    TransferDraftErrors.wrongMinimumPasswordLength
  );
  const doesPswdDisobeyExtendedPswdRequirement: boolean =
    transferDraft.errors.has(
      TransferDraftErrors.disobeysExtendedPasswordRequirements
    );
  const isPswdInvalid: boolean =
    isPswdFieldBlank ||
    isPswdTooShort ||
    doesPswdDisobeyExtendedPswdRequirement;

  const pswdFieldHelperText: string = isPswdInvalid
    ? getPasswordFieldHelperText(transferDraft.errors, environment, i18n)
    : "";

  const isRepeatPswdBlank: boolean = transferDraft.errors.has(
    TransferDraftErrors.blankRepeatPassword
  );
  const isDifferentFromPswd: boolean = transferDraft.errors.has(
    TransferDraftErrors.invalidRepeatPassword
  );
  const isRepeatPswdInvalid: boolean = isRepeatPswdBlank || isDifferentFromPswd;

  const repeatPswdFieldHelperText: string = isRepeatPswdInvalid
    ? getRepeatPasswordFieldHelperText(transferDraft.errors, i18n)
    : "";

  return (
    <div className="flex flex-row gap-x-5">
      <TextField
        value={transferDraft.password}
        error={isPswdInvalid}
        helperText={pswdFieldHelperText}
        variant="filled"
        required
        fullWidth
        type="password"
        label={t(`Password`)}
        InputProps={{
          disableUnderline: true,
          onBlur: (prop) => {
            transferDraftDispatch({
              type: TransferDraftActionType.passwordValidate,
              inputtedPassword: prop.target.value,
              environment: environment,
            });
            if (transferDraft.repeatPassword) {
              transferDraftDispatch({
                type: TransferDraftActionType.repeatPasswordValidate,
                inputtedRepeatPassword: transferDraft.repeatPassword,
              });
            }
          },
          endAdornment: environment.extendedPasswordCriteriaEnabled ? (
            <InputAdornment position="end">
              <PasswordInfoPopOver
                minimumPasswordLength={environment.minimumPasswordLength}
              />
            </InputAdornment>
          ) : undefined,
        }}
        onChange={(newValue) => {
          transferDraftDispatch({
            type: TransferDraftActionType.passwordUpdate,
            updatedPassword: newValue.target.value,
          });
        }}
      />
      <TextField
        value={transferDraft.repeatPassword}
        error={isRepeatPswdInvalid}
        helperText={repeatPswdFieldHelperText}
        variant="filled"
        required
        fullWidth
        type="password"
        label={t(`Repeat password`)}
        InputProps={{
          disableUnderline: true,
          onBlur: (prop) =>
            transferDraftDispatch({
              type: TransferDraftActionType.repeatPasswordValidate,
              inputtedRepeatPassword: prop.target.value,
            }),
        }}
        onChange={(newValue) => {
          transferDraftDispatch({
            type: TransferDraftActionType.repeatPasswordUpdate,
            updatedRepeatPassword: newValue.target.value,
          });
        }}
      />
    </div>
  );
}

const getPasswordFieldHelperText = (
  transferDraftErrors: ts.Set<TransferDraftErrors>,
  environment: Environment,
  i18n: i18n
): string => {
  const isPasswordBlank: boolean = transferDraftErrors.has(
    TransferDraftErrors.blankPassword
  );

  if (isPasswordBlank) {
    return i18n.t(`Please enter the password`);
  }

  const isWrongMinimumPasswordLength: boolean = transferDraftErrors.has(
    TransferDraftErrors.wrongMinimumPasswordLength
  );

  const isExtendedPasswordCriteriaEnabled: boolean =
    environment.extendedPasswordCriteriaEnabled;

  const disobeysExtendedPasswordCriteriaEnabled: boolean =
    transferDraftErrors.has(
      TransferDraftErrors.disobeysExtendedPasswordRequirements
    );

  if (isWrongMinimumPasswordLength) {
    return i18n.t(
      `must be at least {{minimumPasswordLength}} characters long`,
      {
        minimumPasswordLength: environment.minimumPasswordLength,
      }
    );
  }

  if (!isExtendedPasswordCriteriaEnabled) {
    return "";
  }

  if (disobeysExtendedPasswordCriteriaEnabled) {
    return i18n.t(`Password criteria not met`);
  }

  return "";
};

const getRepeatPasswordFieldHelperText = (
  transferDraftErrors: ts.Set<TransferDraftErrors>,
  i18n: i18n
) => {
  const isRepeatPswdBlank: boolean = transferDraftErrors.has(
    TransferDraftErrors.blankRepeatPassword
  );
  const isDifferentFromPswd: boolean = transferDraftErrors.has(
    TransferDraftErrors.invalidRepeatPassword
  );

  return isRepeatPswdBlank
    ? i18n.t(`Please enter the password`)
    : isDifferentFromPswd
    ? i18n.t(`The passwords must match`)
    : "";
};
