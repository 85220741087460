import { Set } from "typescript";

interface PublicboxInfo {
  key: number;
  name: string;
  publicuserIdx: number;
}

interface PublicProfile {
  uid: string;
  name: string;
  email: string;
  hasAvatar: Boolean;
  fingerprint: [
    {
      name: string;
      codePoint: number;
      title: string;
    }
  ];
  locale: string;
  timeZone: string;
}

interface PublicboxDetails {
  idx: number;
  title: string;
  vendor: string;
  helpUrl: URL;
  description: string;
  uploadQuota: number;
  uploadVolume: number;
  url: URL;
  validDate: Date;
  email: string;
  vanityBase: URL;
  hasVanityLink: boolean;
  vanityLink?: string;
  isMyPublic: boolean;
  myPublicOwner: PublicboxOwner;
  receivers: PublicboxOwner[];
  groups?: Group[];
  folders?: Folder[];
}

// Todo check for optional fields
interface Environment {
  server_version: number;
  storagehostIdx: number;
  product_name: string;
  product_url: URL;
  provider_name: string;
  provider_url: URL;
  data_privacy_url_de: URL;
  data_privacy_url_en: URL;
  system_status_url: URL;
  available_lang: string[];
  offered_clients: string[];
  motd: string;
  show_remote_access: boolean;
  password_autocomplete: boolean;
  self_register_promo: boolean;
  freemiumEnabled: boolean;
  locale: string;
  mypublic_enabled: boolean;
  privacyModeEnabled: boolean;
  keepMeLoggedInChecked: boolean;
  minimumPasswordLength: number;
  extendedPasswordCriteriaEnabled: boolean;
  transferPasswordRequired: boolean;
  ttlEnabled: boolean;
  priorityEnabled: boolean;
  recipientAuthenticationEnabled: boolean;
  inviteEnabled: boolean;
  portalUrl: URL;
  timeZone: string;
  customerName: string;
  expiration: Expiration;
  privacyStatementUrl?: URL;
  legalNoticeUrl?: URL;
  browserLanguage: string;
  self_register_enabled: boolean;
  self_register_url?: URL;
  passwordRecoveryEnabled: boolean;
  passwordRecoveryMessage: string;
  lang: string;
}

interface Expiration {
  values: number[];
  default: number;
}

interface PublicboxOwner {
  email: string;
  realname: string;
  uid?: string;
}

interface BaseAnon {
  email: string;
  uid?: string;
}

interface TransferDraftAnon extends BaseAnon {
  name: string;
}

interface TransferAnon extends BaseAnon {
  realname: string;
}

interface TransferReceiver extends TransferAnon {
  portalUrl?: string;
  type?: string;
  clientType?: string;
}

interface Sender extends TransferAnon {
  portalUrl?: string;
}

interface Group {
  idx: number;
  name: string;
}

interface Folder {
  idx: number;
  name: string;
}

/// Files that have been added to the transfer draft
type TransferDraftFile = [SkpFile, File];

interface TransferDraft {
  description: string;
  subject: string;
  receivers?: TransferReceiver[];
  files: TransferDraftFile[];
  anon: TransferDraftAnon;
  errors: Set<TransferDraftErrors>;
  acknowledgement: boolean;
  ttl?: number;
  priority?: number;
  protectionType: ProtectionType;
  password?: string;
  repeatPassword?: string;
}

enum ProtectionType {
  STANDARD,
  PASSWORTSCHUTZ,
  ANGEMELDETEREMPFÄNGER,
}

enum TransferDraftErrors {
  blankSenderName,
  invalidSenderEmail,
  invalidFileCount,
  blankPassword,
  wrongMinimumPasswordLength,
  disobeysExtendedPasswordRequirements,
  blankRepeatPassword,
  invalidRepeatPassword,
}

// TODO check with backend team which field are optional/nullable.
interface Transfer {
  recipientId?: string;
  isProtected?: boolean;
  passwordAttempts?: number;
  isBlocked?: boolean;
  isLocked?: boolean;
  isRecipientAuthenticationEnabled?: boolean;
  sender?: Sender;
  receiver?: TransferReceiver;
  uploadTimestamp?: Date;
  accessTimestamp?: Date;
  downloadTimestamp?: Date;
  expirationTimestamp?: Date;
  subject?: string;
  description?: string;
  priority?: number;
  isInDrive?: boolean;
  isExpired?: boolean;
  isBounced?: boolean;
  isUnread?: boolean;
  malwareStatus?: MalwareStatus;
  processingState?: TransferProcessingState;
  bounceReason?: string;
  directLink?: string;
  totalFileSize?: number;
  files?: SkpTransferFile[];
  anon?: TransferAnon;
}
enum MalwareStatus {
  ok,
  unknown,
  error,
  infected,
}

enum TransferProcessingState {
  newTransfer,
  malwareScan,
  hashCalculation,
  notification,
  completed,
}
interface SkpFile {
  name: string;
  size: number;
  id: string;
}

interface SkpTransferFile extends SkpReservedFile {
  url: URL;
  hashsum: string;
  malware: SkpMalware;
  downloadCounter: number;
  mimeType: string;
}

interface SkpReservedFile extends SkpFile {
  objectId: string;
}

interface MailboxReservationRequest {
  subject?: string;
  description?: string;
  recipientNotification?: boolean;
  deliveryNotification?: boolean;
  recipientAuthentication?: boolean;
  ttl?: number;
  // TODO check if this should rather be an enum
  priority?: number;
  signatureId?: number;
  publicBoxIdx: number;
  receivers?: TransferReceiver[];
  anon: TransferDraftAnon;
  files: SkpFile[];
  protection: SkpReservationProtection;
}

interface SkpReservationProtection {
  enabled: boolean;
  key?: string;
}

interface Reservation {
  token: string;
  files: SkpReservedFile[];
  totalSize: number;
}

interface SkpMalware {
  status: MalwareStatus;
  text: string;
}

interface SkpUploadInfo {
  size: number;
  chunkStart: number;
  chunkEnd: number;
  totalSize: number;
}

interface ApiException {
  error: {
    code: number;
    message: string;
    details: string[];
  };
}

export type {
  PublicProfile,
  PublicboxInfo,
  PublicboxDetails,
  Environment,
  Expiration,
  PublicboxOwner,
  Group,
  Folder,
  Transfer,
  TransferDraft,
  MailboxReservationRequest,
  Reservation,
  SkpFile,
  SkpReservedFile,
  TransferDraftAnon,
  TransferAnon,
  TransferReceiver,
  TransferDraftFile,
  SkpUploadInfo,
  ApiException,
};

export {
  MalwareStatus,
  TransferProcessingState,
  TransferDraftErrors,
  ProtectionType,
};
