import { useState, useEffect, useCallback } from "react";

/**
 * A hook that uses the Screen Wake Lock API to keep the screen awake during long-time period tasks,
 * eg. during transfer upload which is the only use-case for now.
 *
 * It allows a component to request a screen wake lock.
 *
 * If the tab looses visibility (user switches tabs or minimises) the wake lock is automatically lost.
 * This hook will take care of re-acquiring the wake lock when the tab regains visibility,
 *
 *
 * @returns {Object} - An object containing:
 *                      - requestWakeLock {Function}: (async)requests screen wake lock.
 *                      - releaseWakeLock {Function}: Releases wake lock.
 *                      - isWakeLockActive {boolean}: `true` if wake lock is active.
 *
 * NOTE:
 *! - This hook requires the `Screen Wake Lock API` support in the browser.
 */
const useWakeLock = () => {
  const [wakeLock, setWakeLock] = useState<WakeLockSentinel | null>(null);
  const [isWakeLockActive, setIsWakeLockActive] = useState(false);
  const isWakelockSupported: boolean = "wakeLock" in navigator;

  const requestScreenWakeLock = useCallback(async () => {
    if (!isWakeLockActive) {
      try {
        if (isWakelockSupported) {
          const newWakeLock = await navigator.wakeLock.request("screen");
          setWakeLock(newWakeLock);
          setIsWakeLockActive(true);
        } else {
          console.warn("Wake Lock API not supported on this browser.");
        }
      } catch (error) {
        console.error(`Wake Lock request failed: ${error}`);
      }
    }
  }, [isWakeLockActive, isWakelockSupported]);

  // This function is primarily used to manually release wakelock.
  const releaseScreenWakeLock = useCallback(async () => {
    if (wakeLock?.released === false) {
      wakeLock.onrelease = () => {
        setWakeLock(null);
      };
      await wakeLock.release();
    }
  }, [wakeLock]);

  //
  //
  //
  // This use-effect is to handle wakelock's `release` event fired when wakelock is released automatically (page loses visibility) or manually.
  useEffect(() => {
    const handleWakelockRelease = (event: Event) => {
      setIsWakeLockActive(false);
      console.log("Wake Lock released");
    };

    if (wakeLock) {
      wakeLock.addEventListener("release", handleWakelockRelease);
    }

    return () => {
      wakeLock?.removeEventListener("release", handleWakelockRelease);
    };
  }, [wakeLock, isWakeLockActive]);

  //
  //
  //
  // This use-effect is to re-acquire wake lock when tab regains focus after losing focus or getting minimized.
  useEffect(() => {
    const handleVisibilityChange = (event: Event) => {
      if (wakeLock?.released && document.visibilityState === "visible") {
        requestScreenWakeLock();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isWakeLockActive, wakeLock, requestScreenWakeLock]);

  //
  //
  //
  // This use-effect is to release wake lock when the component, which uses this hook, unmounts.
  useEffect(() => {
    return () => {
      releaseScreenWakeLock();
    };
  }, [wakeLock, releaseScreenWakeLock]);

  return { requestScreenWakeLock, releaseScreenWakeLock, isWakeLockActive };
};

export default useWakeLock;
