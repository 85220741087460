type SpinnerProp = {
  size: number;
  color?: string;
};

export const Spinner = (spinnerProp: SpinnerProp) => {
  return (
    <div className="flex h-full w-full items-center justify-center pb-[5px] pl-[64px] pr-[64px] pt-[5px]">
      <div
        className="animate-spin rounded-full border-t-4 border-solid bg-transparent"
        style={{
          height: spinnerProp.size,
          width: spinnerProp.size,
          borderColor: spinnerProp.color ?? "var(--primary-color)",
        }}
      />
    </div>
  );
};
