import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { FormEvent } from "react";

export default function DebugPage() {
  function handleOpenTransfer(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson);

    window.open("/transfer/get/" + formJson.transferId, "_blank");
  }

  return (
    <div className="h-screen bg-neutral-800 p-16 font-mono text-white">
      <h1>Debug page</h1>
      <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:gap-6 xl:gap-8">
        <div className="from-0% to-70% items-end overflow-hidden rounded-lg border border-slate-600 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#03b4ad20] to-transparent p-4 transition-all hover:border-slate-500 hover:from-[#03b4ad30]">
          <h2 className="pb-4">Mailbox</h2>
          <h4>Jagmit 👳🏽‍♂️</h4>
          <Stack direction={"row"} spacing={1} className="py-2">
            <Chip
              label="vanity"
              component="a"
              href="mailbox/js"
              variant="outlined"
              color="primary"
              clickable
            />
            <Chip
              label="public"
              component="a"
              href="mailbox/13513/54825/public.html"
              variant="outlined"
              color="primary"
              clickable
            />
          </Stack>
          <h4 className="pt-1">Apoorv 🧑🏽</h4>
          <Stack direction={"row"} spacing={1} className="py-2">
            <Chip
              label="vanity"
              component="a"
              href="mailbox/as+test"
              variant="outlined"
              color="primary"
              clickable
            />
            <Chip
              label="public"
              component="a"
              href="mailbox/13476/94640/public.html"
              variant="outlined"
              color="primary"
              clickable
            />
          </Stack>
        </div>

        <div className="from-0% to-70% items-end overflow-hidden rounded-lg border border-slate-600 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#03b4ad20] to-transparent p-4 transition-all hover:border-slate-500 hover:from-[#03b4ad30]">
          <h2 className="pb-4">Get page</h2>
          <form method="form" onSubmit={handleOpenTransfer}>
            <div className="flex flex-row items-stretch gap-2 ">
              <input
                type="text"
                name="transferId"
                placeholder="Transfer ID"
                className=" focus rounded bg-neutral-400/20 py-2 px-4 placeholder-white placeholder-opacity-80 outline-offset-2 focus:border focus:border-cyan-300 focus:placeholder-opacity-100 focus:outline-none focus:ring-0"
              ></input>

              <button
                type="submit"
                className="rounded bg-neutral-700 py-2 px-4 font-bold text-white hover:bg-neutral-600"
              >
                open
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
