import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import { Suspense, lazy, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { downloadFile } from "../../api";
import { Spinner } from "../../components/spinner";

type FilePreviewProp = {
  filePreviewUrl?: URL;
  mimeType?: string;
  fileIndex?: number;
  fileName?: string;
  transferRecipientId?: string;
};

type PreviewProp = FilePreviewProp & {
  onPreviewLoad: (isPreviewLoaded: boolean) => void;
};

type PreviewFallbackProp = {
  fileIndex?: number;
  fileName?: string;
  transferRecipientId?: string;
};

export default function FilePreview(filePreviewProp: FilePreviewProp) {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  return (
    <>
      <div className={isLoading ? "" : "hidden"}>
        <Spinner size={80} />
      </div>
      <div className={isLoading ? "hidden" : "shadow-xl"}>
        <Preview
          fileIndex={filePreviewProp.fileIndex}
          fileName={filePreviewProp.fileName}
          filePreviewUrl={filePreviewProp.filePreviewUrl}
          mimeType={filePreviewProp.mimeType}
          transferRecipientId={filePreviewProp.transferRecipientId}
          onPreviewLoad={(isPreviewLoaded) => {
            setIsLoading(!isPreviewLoaded);
          }}
        />
      </div>
    </>
  );
}

function Preview(previewProp: PreviewProp) {
  const cantShowPreviewRef = useRef<boolean>(false);
  const onLoad = () => previewProp.onPreviewLoad(true);
  const onError = () => {
    cantShowPreviewRef.current = true;
    previewProp.onPreviewLoad(true);
  };
  const ReactPlayer = lazy(() => import("react-player"));
  const mimeType = previewProp.mimeType?.split("/") ?? "";

  if (cantShowPreviewRef.current) {
    return (
      <PreviewFallbackUI
        fileIndex={previewProp.fileIndex}
        fileName={previewProp.fileName}
        transferRecipientId={previewProp.transferRecipientId}
      />
    );
  } else {
    if (previewProp.mimeType?.endsWith("pdf")) {
      return (
        <iframe
          title={previewProp.fileName}
          src={previewProp.filePreviewUrl?.href + "#toolbar=0&page=1&zoom=70"}
          className="translate-x-[-23.5%] shadow-md "
          width="188%"
          height="780px"
          onLoad={onLoad}
          onError={onError}
        />
      );
    } else {
      switch (mimeType[0]) {
        case "video":
        case "audio":
          return (
            <Suspense fallback={<Spinner size={80} />}>
              <ReactPlayer
                url={previewProp.filePreviewUrl?.href}
                stopOnUnmount={true}
                controls={true}
                onReady={onLoad}
                onError={onError}
                width="max-content"
              />
            </Suspense>
          );
        default:
          return (
            <img
              className="h-3/4 shadow-md"
              src={previewProp.filePreviewUrl?.href}
              alt={previewProp.fileName}
              onLoad={onLoad}
              onError={onError}
            />
          );
      }
    }
  }
}

function PreviewFallbackUI(previewFallbackProp: PreviewFallbackProp) {
  const { t } = useTranslation();
  return (
    <div className="h-[120px] w-[300px] flex-col items-center justify-center rounded-lg bg-[#4C494C] pt-5 text-white">
      <div className="flex justify-center">{t(`No preview available`)}</div>
      <div className="mt-[20px] flex justify-center">
        <Button
          className="text-[8px]"
          type="button"
          variant="contained"
          size="small"
          sx={{
            borderRadius: 10,
          }}
          onClick={() => {
            downloadFile({
              fileIndex: previewFallbackProp.fileIndex,
              fileName: previewFallbackProp.fileName,
              transferRecipientId: previewFallbackProp.transferRecipientId,
            });
          }}
        >
          <DownloadIcon
            sx={{
              color: "white",
              fontSize: "15px",
              marginRight: "5px",
            }}
          />
          {t(`Download`)}
        </Button>
      </div>
    </div>
  );
}
