import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton/IconButton";
import Popover from "@mui/material/Popover/Popover";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TransferDraftErrors } from "../../../entities";
import { useTransferDraft } from "../../../providers/transferDraft";

type PasswordInfoPopOverProp = {
  minimumPasswordLength: number;
};

export default function PasswordInfoPopOver({
  minimumPasswordLength,
}: PasswordInfoPopOverProp) {
  const transferDraft = useTransferDraft();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? "simple-popover" : undefined;
  const isInvalidPassword =
    transferDraft.errors.has(
      TransferDraftErrors.disobeysExtendedPasswordRequirements
    ) ||
    transferDraft.errors.has(TransferDraftErrors.wrongMinimumPasswordLength);
  return (
    <>
      <IconButton aria-label="info" onClick={handleClick}>
        <InfoIcon
          style={{
            fontSize: 25,
            color: isInvalidPassword
              ? "#C40606"
              : isOpen
              ? "#04B4AC"
              : "#999999",
          }}
        />
      </IconButton>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {isInvalidPassword ? (
          <ErrorContent minPasswordLength={minimumPasswordLength} />
        ) : (
          <InfoContent minPasswordLength={minimumPasswordLength} />
        )}
      </Popover>
    </>
  );
}

type InfoContentProp = {
  minPasswordLength: number;
};

function InfoContent({ minPasswordLength }: InfoContentProp) {
  const { t } = useTranslation();
  return (
    <div className="bg-[#FCFAF5] p-[40px]">
      {t(`Password protection is required for this transfer.`)}
      <div className="py-[24px]">
        {t(
          `The password must be at least {{minPasswordLength}} characters long and must fullfil 3 of the criteria:`,
          { minPasswordLength: minPasswordLength }
        )}
      </div>
      <ul className="list-inside list-disc">
        <li>{t(`contains capital letters`)}</li>
        <li>{t(`contains lower case `)}</li>
        <li>{t(`contains numbers`)}</li>
        <li>{t(`contains special characters (e.g. %&?)`)}</li>
      </ul>
    </div>
  );
}

type ErrorContentProp = {
  minPasswordLength: number;
};

function ErrorContent({ minPasswordLength }: ErrorContentProp) {
  const { t } = useTranslation();
  return (
    <div className="bg-[#F7DFE0] p-[40px] text-[15px]">
      <div className="pb-[24px]">
        {t(
          `Please use a password that is at least {{minPasswordLength}} characters long and meets 3 of the criteria:`,
          { minPasswordLength: minPasswordLength }
        )}
      </div>
      <ul className="list-inside list-disc">
        <li>{t(`contains capital letters`)}</li>
        <li>{t(`contains lower case letters`)}</li>
        <li>{t(`contains numbers`)}</li>
        <li>{t(`contains special characters (e.g. %&?)`)}</li>
      </ul>
    </div>
  );
}
