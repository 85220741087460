import { ReactNode } from "react";

type LockedTransferContentSkeletonSkeletonProp = {
  children: ReactNode;
};

export const LockedTransferContentSkeleton = (
  lockedTransferSkeletonProp: LockedTransferContentSkeletonSkeletonProp
) => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      {lockedTransferSkeletonProp.children}
    </div>
  );
};
