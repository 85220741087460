import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { ReactNode } from "react";
import useSkpTheme from "../../hooks/useSkpTheme";
import { cssVar } from "../../utils/cssVar";

type UploadDialogThemeProviderProp = {
  children: ReactNode;
  progress: number;
};

export const UploadDialogThemeProvider = ({
  children,
  progress,
}: UploadDialogThemeProviderProp) => {
  useSkpTheme();
  const isUploadComplete: boolean = progress >= 100;

  const uploadDialogTheme = createTheme({
    palette: {
      primary: {
        main: cssVar("--primary-color"),
      },
      secondary: {
        main: cssVar("--primary-light-color"),
      },
    },
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            strokeWidth: 2.65,
            strokeLinecap: "round",
          },
          determinate: {
            transition: "color 1.5s cubic-bezier(0.4, 0, 0.6, 1)",
            color: isUploadComplete
              ? cssVar("--post-succesful-upload-circular-progress-bar-color")
              : cssVar("--ongoing-upload-circular-progress-bar-color"),
            position: "absolute",
            top: -2,
            left: -1.7,
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fill: cssVar("--primary-color"),
          },
        },
      },
    },
  });

  return <ThemeProvider theme={uploadDialogTheme}>{children}</ThemeProvider>;
};
